import axios from "axios"
import { UserModel } from "./User.Model"
import { Server } from "../../../values/Server"


class ErrorLog {

    async getErrorLogs(pagination, addedQuery) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/error-logs?pagination[page]=${pagination.page}&pagination[limit]=${pagination.limit}${addedQuery}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }

    async getErrorLogById(id) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/error-log/${id}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }

    async setErrorLogClose(id) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${Server.API_ENDPOINT}/error-log/${id}/close`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }

    async setErrorLogOpen(id) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${Server.API_ENDPOINT}/error-log/${id}/open`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
}

export const ErrorLogModel = new ErrorLog()