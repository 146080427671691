import React, { useEffect, useState } from "react"
import { Containers, FlexRowContainer } from "../../../components/Container"
import { InputPrimary } from "../../../components/Inputs"
import { HorizontalSpacing, IconImage, LoadingComponent, Miscs, VerticalSpacing } from "../../../components/Miscs"
import { IssueCategoryModel } from "../../../services/database/models/feedback/IssueCategory.Model"
import { Table } from "../../../components/Table"
import { IconAsset } from "../../../assets/IconAsset"
import { TypographyDefault } from "../../../components/Typography"

export function BugReportCategories() {
    const [input, setInput] = useState({
        label: '',
        value: ''
    })
    const [loading, setLoading] = useState(true)
    const [categories, setCategories] = useState([])
    const [savingState, setSavingState] = useState(0)
    async function Init() {
        const categories = await IssueCategoryModel.getIssueCategories({
            page: 1,
            limit: 10
        })
        setCategories(categories)
        setLoading(false)
    }

    async function onTextChange(key, value) {
        setInput({
            ...input,
            [key]: value
        })
    }
    async function onRemove(id) {
        setSavingState(1)
        await IssueCategoryModel.removeIssueCategory(id)
        const categories = await IssueCategoryModel.getIssueCategories({
            page: 1,
            limit: 10
        })
        setSavingState(2)
        setCategories(categories)
    }
    // When submitting a new issue category
    async function onSubmit() {
        if (!input.label || !input.value) return alert('Must have label and value')
        setSavingState(1)
        await IssueCategoryModel.addIssueCategory(input.label, input.value)
        const categories = await IssueCategoryModel.getIssueCategories({
            page: 1,
            limit: 10
        })
        setSavingState(2)
        setCategories(categories)
        setInput({
            label: '',
            value: ''
        })
    }

    useEffect(() => {
        Init()
    }, [])
    return (

        <div>
        
            {
                loading
                    ? <LoadingComponent />
                    :
                    <>
                        <FlexRowContainer>
                            <InputPrimary placeholder='Label' value={input.label} onChange={(evt) => onTextChange('label', evt.target.value)}/>
                            <HorizontalSpacing />
                            <InputPrimary placeholder='Value' value={input.value} onChange={(evt) => onTextChange('value', evt.target.value)}/>
                            <IconImage src={IconAsset.Plus} onClick={onSubmit}/>
                            {
                                savingState == 1 &&
                                <FlexRowContainer styles={{alignItems: 'center'}}>
                                    <IconImage src={IconAsset.Loading} />
                                    <TypographyDefault>Saving...</TypographyDefault>
                                </FlexRowContainer>
                            }
                            {
                                savingState == 2 &&
                                <FlexRowContainer styles={{alignItems: 'center'}}>
                                    <IconImage src={IconAsset.CloudUpload} />
                                    <TypographyDefault>Saved</TypographyDefault>
                                </FlexRowContainer>
                            }
                        </FlexRowContainer>
                        <VerticalSpacing />
                        <FlexRowContainer style={{overflowY: 'scroll', height: '65vh' }}>
                            <table style={Table.default}>
                                <tr>
                                    <th style={Table.identifier}></th>
                                    <th style={Table.identifier}>Label</th>
                                    <th style={Table.identifier}>Value</th>
                                </tr>
                                {
                                    categories.map((item, index) => {
                                        return (
                                            <tr>
                                                <th style={Table.cells}>
                                                    <img src={IconAsset.Trashcan} style={Miscs.clickableIcon} draggable={false} onClick={() => onRemove(item.id)} />
                                                </th>
                                                <th style={Table.cells}>{item.attributes.label}</th>
                                                <th style={Table.cells}>{item.attributes.value}</th>
                                            </tr>
                                        )
                                    })
                                }
                            </table>
                        </FlexRowContainer>
                    </>
            }
        </div>
    )
}

