import { useEffect, useState } from "react";
import { TypographyHeader2, TypographyHeader3, TypographyGray } from "../../../components/Typography";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { BarangayRegistrationModel } from "../../../services/database/models/BarangayRegistration.Model";
import { FlexColumnContainer, FlexRowContainer } from "../../../components/Container";
import { ButtonDefault, ButtonWhite } from "../../../components/Buttons";
import { LoadingComponent } from "../../../components/Miscs";


export function BarangayRegistrationOverview() {

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [barangayRegistration, setBarangayRegistration] = useState()
    const [isLoading, setLoading] = useState(true)

    async function getBarangayRegistration() {
        const barangayRegistrationId = searchParams.get('id')
        const getBarangayRegistration = await BarangayRegistrationModel.getBarangayRegistrationById(barangayRegistrationId)
        setBarangayRegistration(getBarangayRegistration.data)
        setLoading(false)
    }
    async function approveBarangayRegistration() {
        await BarangayRegistrationModel.approveBarangayRegistration(barangayRegistration.id)
        alert('Approved request')
        navigate(`/dashboard/barangay-registrations`)
    }
    async function declineBarangayRegistration() {
        await BarangayRegistrationModel.declineBarangayRegistration(barangayRegistration.id)
        alert('Declined request')
        navigate(`/dashboard/barangay-registrations`)
    }


    useEffect(() => {
        getBarangayRegistration()
    }, [])


    return (
        <div>
            <TypographyHeader2>
                Barangay Registration Overview
            </TypographyHeader2>
            {
                isLoading
                    ? <LoadingComponent/> 
                    : <FlexColumnContainer style={{padding: 20}}>
                        <FlexColumnContainer>
                            <p>
                                Region
                            </p>
                            <TypographyGray>
                                { barangayRegistration.attributes.region?.Name }
                            </TypographyGray>
                            <p>
                                Province
                            </p>
                            <TypographyGray>
                                { barangayRegistration.attributes.province?.Name }
                            </TypographyGray>
                            <p>
                                Municipal
                            </p>
                            <TypographyGray>
                                { barangayRegistration.attributes.municipal?.Name }
                            </TypographyGray>
                            <p>
                                Barangay
                            </p>
                            <TypographyGray>
                                { barangayRegistration.attributes.barangay?.Name }
                            </TypographyGray>
                            <p>
                                Assign First Admin to
                            </p>
                            <TypographyGray>
                                { barangayRegistration.attributes.ownerEmail }
                            </TypographyGray>
                            <p>
                                Requested at
                            </p>
                            <TypographyGray>
                                { 
                                    barangayRegistration.attributes.createdAt &&
                                        `${new Date(barangayRegistration.attributes.createdAt).toDateString()} ${new Date(barangayRegistration.attributes.createdAt).toTimeString()}`
                                }
                            </TypographyGray>
                        </FlexColumnContainer>
                        <div style={{height: 20}}></div>
                        <FlexRowContainer style={{width: '50vw'}}>
                            <ButtonWhite value={'Decline'} onClick={declineBarangayRegistration}/>
                            <div style={{width: 20}}></div>
                            <ButtonDefault value={'Approve'} onClick={approveBarangayRegistration}/>
                        </FlexRowContainer>
                    </FlexColumnContainer>
            }
        </div>
    )
}