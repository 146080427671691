import axios from "axios"
import { Server } from "../../../../values/Server"
import { UserModel } from "../User.Model"

class Feedback {
    /**
     * @description Fetch User Feedbacks
     * @param {*} param0 
     * @returns 
     */
    getFeedbacks({ page, limit, query }) {
        return new Promise(async (resolve, reject) => {
            const accessToken = await UserModel.getAccessToken()
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/feedbacks?pagination[page]=${page}&pagination[limit]=${limit}&${query}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => resolve(snapshot.data))
            .catch(error => reject(error))
        })
    }
    /**
     * @description Fetch User Feedbacks
     * @param {*} param0 
     * @returns 
     */
    getFeedbackById(id) {
        return new Promise(async (resolve, reject) => {
            const accessToken = await UserModel.getAccessToken()
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/feedback/${id}?include=[user]`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => resolve(snapshot.data))
            .catch(error => reject(error))
        })
    }
    
}

export const FeedbackModel = new Feedback()