
import { createTheme } from '@mui/material';

export const theme = createTheme({
    typography: {
        fontFamily: 'Nunito',
        button: {
            textTransform: 'none',
        }
    }
})