import axios from "axios"
import { Server } from "../../../values/Server"
import { UserModel } from "./User.Model"

class Service {
    /**
     * @description The method getAllServices returns a promise that resolves when the data is fetched and returns the data.
     * If there is an error it will reject the promise and return the error.
     * @returns 
     */
    async getAllServices() {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/services`
            }).then(snapshot => {
                resolve(snapshot.data.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    } 
    /**
     * @description In this code, the function updateService takes two parameters, name and isMaintenance and returns a Promise. 
     * if the promise is resolved the service will be updated. if there is an error the promise will be rejected.
     * @param {*} name 
     * @param {*} isMaintenance 
     * @returns 
     */
    async updateService(name, isMaintenance) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'PATCH',
                url: `${Server.API_ENDPOINT}/service/${name}/isMaintenance/${isMaintenance}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                
                resolve(snapshot.data.data)
            }).catch(error => {
                console.log(error)
                reject(error.response.data)
            })
        })
    }

    async getServerVersion() {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/service/server/version`,
            }).then(snapshot => {
                
                resolve(snapshot.data.data)
            }).catch(error => {
                console.log(error)
                reject(error.response.data)
            })
        })
    }
}

export const ServiceModel = new Service()