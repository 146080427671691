import { useNavigate, useSearchParams } from "react-router-dom";
import { TypographyHeader2, TypographyHeader3, TypographyDefault, TypographyGray } from "../../../components/Typography";
import { ErrorLogModel } from "../../../services/database/models/ErrorLog.Model";
import { useEffect, useState } from "react";
import { FlexColumnContainer, FlexRowContainer } from "../../../components/Container";
import { LoadingComponent, ToggleSwitch } from "../../../components/Miscs";
import { BILIS_colors } from "../../../values/String";
import { IconAsset } from "../../../assets/IconAsset";


export function ErrorLogOverview() {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [errorLog, setErrorLog] = useState()
    const [isLoading, setLoading] = useState(true)

    async function getErrorLog() {
        const errorLogId = searchParams.get('id')
        const getErrorLog = await ErrorLogModel.getErrorLogById(errorLogId)
        setErrorLog(getErrorLog.data)
        setLoading(false)
    }

    async function closeErrorLog() {
        setLoading(true)
        await ErrorLogModel.setErrorLogClose(errorLog.id)
        await getErrorLog()
        navigate('/dashboard/error-logs')
    }
    async function openErrorLog() {
        setLoading(true)
        await ErrorLogModel.setErrorLogOpen(errorLog.id)
        await getErrorLog()
    }


    useEffect(() => {
        getErrorLog()
    }, [])

    return (
        <div>
            <FlexRowContainer style={{alignContent: "center", alignItems: "center"}}>
                <FlexColumnContainer style={{cursor: "pointer"}} onClick={() => {
                    navigate('/dashboard/error-logs')
                }}>
                    <img src={IconAsset.BackButton} style={{ width: "35px", height: "35px" }}></img>
                </FlexColumnContainer>
                <div style={{ marginLeft: 10 }}></div>
                <TypographyHeader2>
                    Error Log Overview
                </TypographyHeader2>
            </FlexRowContainer>
            {
                isLoading
                    ? <LoadingComponent />
                    :
                    <>
                        <FlexColumnContainer style={{ padding: '20px', overflowY: "scroll", height: "90vh", marginBottom: '80px' }}>
                            <TypographyDefault style={{fontWeight: 'bold'}}>Id:</TypographyDefault>
                            <TypographyGray>{errorLog.id}</TypographyGray>
                            <TypographyDefault style={{fontWeight: 'bold'}}>Endpoint:</TypographyDefault>
                            <TypographyGray>{errorLog.attributes.endpoint}</TypographyGray>
                            <TypographyDefault style={{fontWeight: 'bold'}}>Platform:</TypographyDefault>
                            <TypographyGray>{errorLog.attributes.platform}</TypographyGray>
                            <TypographyDefault style={{fontWeight: 'bold'}}>Date:</TypographyDefault>
                            <TypographyGray>{new Date(errorLog.attributes.createdAt).toLocaleString()}</TypographyGray>
                            <TypographyDefault style={{fontWeight: 'bold'}}>Name:</TypographyDefault>
                            <TypographyGray>{errorLog.attributes.message || 'N/A'}</TypographyGray>
                            <TypographyDefault style={{fontWeight: 'bold'}}>Encountered:</TypographyDefault>
                            <TypographyGray>{errorLog.attributes.encountered || '1'} time/s</TypographyGray>
                            <TypographyDefault style={{fontWeight: 'bold'}}>Status:</TypographyDefault>
                            {
                                errorLog.attributes.status === "open"
                                    ?
                                    <FlexColumnContainer style={{ flexDirection: "row", backgroundColor: BILIS_colors.secondary, width: "80px", padding: 10, borderRadius: 10, alignContent: "center", alignItems: "center", cursor: "pointer" }} onClick={closeErrorLog}>
                                        <img src={IconAsset.ChecklistWhite} style={{ width: "20px" }}></img>
                                        <div style={{ width: 10 }}></div>
                                        <p style={{ fontWeight: "bold", color: "white", textAlign: "center", userSelect: "none" }}>Open</p>
                                    </FlexColumnContainer>
                                    :
                                    <FlexColumnContainer style={{ flexDirection: "row", backgroundColor: "#8856E5", width: "80px", padding: 10, borderRadius: 10, alignContent: "center", alignItems: "center", cursor: "pointer" }} onClick={openErrorLog}>
                                        <img src={IconAsset.CheckmarkWhite} style={{ width: "20px" }}></img>
                                        <div style={{ width: 10 }}></div>
                                        <p style={{ fontWeight: "bold", color: "white", textAlign: "center", userSelect: "none" }}>Close</p>
                                    </FlexColumnContainer>
                            }
                            <TypographyDefault style={{fontWeight: 'bold'}}>Meta:</TypographyDefault>
                            <FlexColumnContainer style={{ backgroundColor: "whitesmoke", padding: 20, borderRadius: 10 }}>
                                <TypographyDefault style={{ fontWeight: "bold" }}>{JSON.stringify(errorLog.attributes.meta)}</TypographyDefault>
                            </FlexColumnContainer>
                            <div style={{ marginTop: 20 }}></div>
                            <TypographyDefault style={{fontWeight: 'bold'}}>Stack Trace:</TypographyDefault>
                            <FlexColumnContainer style={{ backgroundColor: "whitesmoke", padding: 20, borderRadius: 10 }}>
                                <TypographyDefault>{errorLog.attributes.stackTrace}</TypographyDefault>
                            </FlexColumnContainer>
                            <div style={{ marginTop: 100 }}></div>

                        </FlexColumnContainer>

                    </>
            }
        </div>
    )

}