import { ArrayField, PlaceholderField, TipMessageField, VariableField } from "./FormComponent"

export const RadioGroupComponent = (variable, placeholder, tipMessage, onChange, onArrayChange, callback, initializedArray) => {
    return <>
        {
            VariableField(variable, onChange)
        }
        {
            PlaceholderField(placeholder, onChange)
        }
        {
            TipMessageField(tipMessage, onChange)
        }
        {
            ArrayField(onArrayChange, callback, initializedArray)
        }
    </>
}