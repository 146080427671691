import { PlaceholderField, PlaceholderValueField, VariableField } from "./FormComponent";

export const TextBoxComponent = (placeholder, placeholderValue, variable, onChange) => {
  return (
    <>
      {PlaceholderField(onChange)}
      {PlaceholderValueField(onChange)}
      {VariableField(onChange)}
    </>
  );
};
