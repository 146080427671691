import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { FlexColumnContainer } from "../../../components/Container"
import { TypographyHeader2 } from "../../../components/Typography"
import DataGrid from "../../../components/DataGrid"
import { ErrorLogModel } from "../../../services/database/models/ErrorLog.Model"
import { Pagination } from "@mui/material"
export function ErrorLogs() {
    const [addedQuery, setAddedQuery] = useState(`&filter[status]=open`)
    const [errorLogsMeta, setErrorLogsMeta] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [defaultFilterContent, setDefaultFilterContent] = useState({
        attribute: "status",
        query: "equals",
        value: "open"
    })
    const [errorLogs, setErrorLogs] = useState()
    const [isLoading, setLoading] = useState(true)
    const navigate = useNavigate()
    //#endregion
    async function getErrorLogs(page = currentPage) {
        const fetchErrorLogs = await ErrorLogModel.getErrorLogs({
            page: page,
            limit: 10
        }, addedQuery)


        let collectedData = []
        await fetchErrorLogs.data.reduce(async (promise, snapshot, index) => {
            await promise
            delete snapshot.attributes.updatedAt
            collectedData.push({id: snapshot.id, 
                message: snapshot.attributes.message,
                status: snapshot.attributes.status,
                platform: snapshot.attributes.platform,
                createdAt: new Date(snapshot.attributes.createdAt).toLocaleString()
            })
        }, Promise.resolve())

        setErrorLogs(collectedData)
        setErrorLogsMeta(fetchErrorLogs.meta)
        setLoading(false)


    }


    useEffect(() => {
        getErrorLogs(1)
    }, [addedQuery])

    return (
        <div>
            <TypographyHeader2>
                Error Logs
            </TypographyHeader2>
            <FlexColumnContainer>
                <DataGrid
                    isLoading={isLoading}
                    headers={[
                        'message',
                        'status',
                        'platform',
                        'createdAt',
                    ]}
                    items={errorLogs}
                    filterOptions={{
                        attributes: [
                            '_id',
                            'name',
                            'platform',
                            'status'
                        ]
                    }}
                    onFilterSubmit={(content) => {
                        setAddedQuery(`&filter[${content.attribute}]=${content.value}`)
                    }}
                    defaultFilterContent={defaultFilterContent}
                    onFilterClear={() => {
                        setAddedQuery()
                    }}
                    onItemClick={(item, index) => {
                        const chosenItem = errorLogs[index]
                        navigate(`/dashboard/error-log?id=${chosenItem.id}`)
                    }}
                    onClickRefresh={getErrorLogs}
                />
            </FlexColumnContainer>
            <FlexColumnContainer style={{ alignItems: "center" }}>
                {
                    errorLogsMeta?.pagination.totalPages > 0 &&
                        <Pagination
                            count={errorLogsMeta?.pagination.totalPages}
                            hidePrevButton={false}
                            onChange={async (data, index) => {
                                setCurrentPage(index)
                                getErrorLogs(index)
                            }}
                        >
                        </Pagination>
                }
            </FlexColumnContainer>
        </div>
    )
}

const styles = {
    spotlight: {
        marginTop: '15px',
        width: '100%',
        height: '80vh'
    },
}