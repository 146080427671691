import axios from "axios"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { IconAsset } from "../assets/IconAsset"
import { Buttons } from "../components/Buttons"
import { Inputs } from "../components/Inputs"
import { Server } from '../values/Server'
import { BILIS_colors } from "../values/String"
import { TypographyError } from "../components/Typography"

export function AuthorizeEmail() {
    const [credentials, setCredentials] = useState()
    const [ loading, setLoading ] = useState(true)
    const [ subscribe, setSubscribe ] = useState()
    const [ error, setError ] = useState()
    const [ disableSubmit, setDisableSubmit ] = useState()

    const navigate = useNavigate()
    function onChange(context, evt) {
        const text = evt.target.value
        setDisableSubmit(false)
        setCredentials({
            ...credentials,
            [context]: text
        })
    }

    /**
     * @description Check for current user available
     * @returns 
     */
    async function checkCurrentUser() {
        const currUser = localStorage.getItem('authToken')
        if (!currUser) return navigate('/login')
        if (!subscribe) {
            setSubscribe(true)
            sendEmail()
        }
    }

    async function confirmCode() {
        const authenticationToken = localStorage.getItem('authToken')
        setLoading(true)
        setDisableSubmit(true)
        try {
            await axios({
                method: 'POST',
                url: `${Server.API_ENDPOINT}/user/me/log-history/email/authorize`,
                data: {
                    data: {
                        authCode: credentials.code
                    }
                },
                headers: {
                    'Authorization': `Bearer ${authenticationToken}`
                }
            })
            return navigate("/pin")
        } catch(error) {
            setLoading(false)
            const err = error.response.data?.error
            if (err.name === 'AUTH_CODE_NOT_MATCH')
                return setError('Invalid Code')
            if (err.name === 'NO_AUTH_CODE_AVAILABLE')
                sendEmail()
        }
    }
    /**
     * @description Send an Email Verification
     */
    async function sendEmail() {
        const authenticationToken = await localStorage.getItem('authToken')
        try {
            await axios({
                method: 'POST',
                url: `${Server.API_ENDPOINT}/user/me/send-email-authorization`,
                headers: {
                    'Authorization': `Bearer ${authenticationToken}`
                }
            })
            setLoading(false)
        } catch(error) {
            setLoading(false)
            const err = error.response.data?.error
            if (err.name === 'AUTH_CODE_NOT_EXISTS')
                return setError('Invalid Code')
            if (err.name === 'AUTH_CODE_EXISTS')
                return setError('Auth code already sent')
            if (err.name === 'LOCATION_ALREADY_AUTHORIZED')
                return navigate("/pin")
        }
    }

    useEffect(() => {
        checkCurrentUser()
    }, [credentials])
    useEffect(() => {}, [loading])
    //#region Components
    const LoadingComponent = () => {
        return (
            <div style={styles.loadingWrapper}>
                <img src={IconAsset.Loading} style={styles.loading}/>
            </div>
        )
    }
    const ButtonComponent = () => {
        return credentials && credentials.code && !disableSubmit
                    ? <input type="submit" style={Buttons.buttonEnabled} onClick={confirmCode}></input>
                    : <input type="submit" style={Buttons.buttonDisabled}></input>   
    }
    //#endregion
    return (
        <div>
            <div style={styles.containerFlex}>
                <img src={IconAsset.BILISLogo} style={{ width: '200px' }} />
                <div style={styles.containerContent}>
                    <h2>Authorize Email</h2>
                    <p>A mail is sent with your email containing the authentication code
                    to authorize this device.</p>
                    <input maxLength={6} type="text" style={Inputs.inputText} placeholder="Enter Code" onChange={(evt) => onChange('code', evt)} />
                    <div style={styles.spacing}></div>
                    {
                        loading 
                        ? <LoadingComponent />
                        : <ButtonComponent />
                    }
                    <div style={{marginTop: 10}}/>
                    {
                        error && <TypographyError>{error}</TypographyError>
                    }
                </div>
            </div>
        </div>
    )
}
const styles = {
     containerFlex: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        alignContent: 'center',
        padding: '30px',
        marginTop: '100px'
    },
    containerContent: {
        display: 'flex',
        width: '400px',
        flexDirection: 'column',
        padding: '25px',
    },
    spacing: {
        height: '10px'
    },
    buttonEnabled: {
        border: 'none',
        borderRadius: '5px',
        padding: '10px',
        color: 'white',
        fontWeight: 'bold',
        cursor: 'pointer',
        background: BILIS_colors.primary
    },
    buttonDisabled: {
        border: 'none',
        borderRadius: '5px',
        padding: '10px',
        color: 'white',
        fontWeight: 'bold',
        background: 'lightgray'
    },
    loading: {
        width: '40px',
        height: '38px'
    },
    loadingWrapper: {
        display: 'flex',
        justifyContent: 'center'
    }
}