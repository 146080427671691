import axios from "axios"
import { Server } from "../../../values/Server"
import { UserModel } from "./User.Model"

class GenesisAdmin {
    async getGenesisAdmins() {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/genesis-admins`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
    async registerToBarangay(userId, clientId) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'PATCH',
                url: `${Server.API_ENDPOINT}/genesis-admin/${userId}/adminAt/barangay/${clientId}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
}

export const GenesisAdminModel = new GenesisAdmin()