import { motion } from "framer-motion"

export function CardPrimary({ style, children, onClick, whileHover, transition}) {
    return (
        <motion.div 
            style={{   
                display: 'flex',
                borderRadius: '5px',
                padding: '2px 10px',
                backgroundColor: 'rgb(245, 245, 245)',
                marginBottom: '10px',
                padding: '10px',
                margin: '5px',
                ...style
            }}
            onClick={onClick}
            whileHover={whileHover}
            transition={transition}
        >
            {children}
        </motion.div>
    )
}

export function CardSecondary({ style, children}) {
    return (
        <div style={{   
            display: 'flex',
            borderRadius: '5px',
            border: '1px solid lightgray',
            padding: '2px 10px',
            marginBottom: '10px',
            padding: '10px',
            margin: '5px',
            ...style
        }}>
            {children}
        </div>
    )
}