import React, { useEffect, useState } from "react"
import { InputPrimary, Inputs } from "../../components/Inputs"
import { IconImage, LoadingComponent, Miscs, VerticalSpacing } from "../../components/Miscs"
import { FlexColumnContainer, FlexRowContainer } from "../../components/Container"
import { useNavigate } from "react-router"
import { UserModel } from "../../services/database/models/User.Model"
import { ApplicationModel } from "../../services/database/models/Application.Model"
import { IconAsset } from "../../assets/IconAsset"
import { CardPrimary } from "../../components/Card"
import { TypographyHeader2, TypographyHeader3 } from "../../components/Typography"

export function ApplicationVersioning() {
    const [isLoading, setLoading] = useState(true)
    const [androidVersions, setAndroidVersions] = useState()
    const [iosVersions, setIosVersions] = useState()
    const [savingState, setSavingState] = useState(0)
    const [input, setInput] = useState({
        android: '',
        ios: ''
    })
    const navigate = useNavigate()
    async function Init() {
        try {
            await UserModel.getCurrentUser()
            await getVersions()
            setLoading(false)
        } catch (error) {
            if (error === 'ACCESS_TOKEN_INVALID')
                return navigate('/pin')
        }
    }
    /**
     * @description Fetch version availables
     */
    async function getVersions() {
        const getAndroidVersions = await ApplicationModel.getApplication('android')
        const getIosVersions = await ApplicationModel.getApplication('ios')
        setAndroidVersions(getAndroidVersions.attributes.version)
        setIosVersions(getIosVersions.attributes.version)
    }
    // Remove a version
    async function removeVersion(os, version) {
        setSavingState(1) // Saving
        if (os == 'android') {
            const index = androidVersions.indexOf(version)
            let versionLeft = []
            for (const androidVersion of androidVersions) {
                if (androidVersion !== androidVersions[index]) {
                    versionLeft.push(androidVersion)
                }
            }
            versionLeft.sort()
            setAndroidVersions(versionLeft)
            await ApplicationModel.updateApplication(os, versionLeft)
        }
        if (os == 'ios') {
            const index = iosVersions.indexOf(version)
            let versionLeft = []
            for (const iosVersion of iosVersions) {
                if (iosVersion !== iosVersions[index]) {
                    versionLeft.push(iosVersion)
                }
            }
            versionLeft.sort()
            setIosVersions(versionLeft)
            await ApplicationModel.updateApplication(os, versionLeft)
        }
        setSavingState(2) // Success
    }
    // Add a version
    async function addVersion(os) {
        setSavingState(1) // Saving
        if (os == 'android') {
            androidVersions.push(input.android)
            setAndroidVersions(androidVersions)
            androidVersions.sort()
            await ApplicationModel.updateApplication(os, androidVersions)
        }
        if (os == 'ios') {
            iosVersions.push(input.ios)
            setIosVersions(iosVersions)
            androidVersions.sort()
            await ApplicationModel.updateApplication(os, iosVersions)
        }
        setSavingState(2) // Success
        setInput({
            ...input,
            [os]: ''
        })

    }
    // Input
    async function onTextChange(os, evt) {
        const text = evt.target.value
        setInput({
            ...input,
            [os]: text
        })
    }
    useEffect(() => {
        Init()
    }, [])

    useEffect(() => { }, [isLoading, androidVersions, savingState])
    //#region Components
    //#endregion
    return (
        <FlexColumnContainer>
            <FlexColumnContainer>
                <TypographyHeader2>
                    Application Versioning
                </TypographyHeader2>
                {
                    savingState == 1 &&
                    <FlexRowContainer style={{alignItems: 'center'}}>
                        <IconImage src={IconAsset.Loading} />
                        <p style={Inputs.textPrimary}>Saving...</p>
                    </FlexRowContainer>
                }
                {
                    savingState == 2 &&
                    <FlexRowContainer style={{alignItems: 'center'}}>
                        <IconImage src={IconAsset.CloudUpload} />
                        <p style={Inputs.textPrimary}>Saved</p>
                    </FlexRowContainer>
                }
            </FlexColumnContainer>
            <p>
                All versions that are published here is available to the public.
            </p>
            {
                isLoading
                    ? <LoadingComponent />
                    :
                    <FlexRowContainer>
                        <div style={styles.availableVersionWrapper}>
                            <TypographyHeader3>
                                Android
                            </TypographyHeader3>
                            <FlexRowContainer>
                                <InputPrimary  placeholder='Add Version' value={input.android} onChange={(evt) => onTextChange('android', evt)}></InputPrimary>
                                <IconImage src={IconAsset.Plus} onClick={(evt) => addVersion('android')} ></IconImage>
                            </FlexRowContainer>
                            <VerticalSpacing />
                            <div style={styles.availableVersionList}>
                                {
                                    !isLoading &&
                                    androidVersions.map((item, index) => {
                                        return (
                                            <CardPrimary key={index} onClick={() => removeVersion('android', item)}>
                                                <FlexRowContainer style={{alignItems: 'center'}}>
                                                    <IconImage src={IconAsset.Trashcan}></IconImage>
                                                    <p style={styles.availableVersionText}>{item}</p>
                                                </FlexRowContainer>
                                            </CardPrimary>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div style={styles.availableVersionWrapper}>
                            <TypographyHeader3>
                                IOS
                            </TypographyHeader3>
                            <FlexRowContainer>
                                <InputPrimary  placeholder='Add Version' value={input.ios} onChange={(evt) => onTextChange('ios', evt)}></InputPrimary>
                                <IconImage src={IconAsset.Plus} onClick={(evt) => addVersion('ios')} ></IconImage>
                            </FlexRowContainer>
                            <VerticalSpacing />
                            <div style={styles.availableVersionList}>
                                {
                                    !isLoading &&
                                    iosVersions.map((item, index) => {
                                        return (
                                            <CardPrimary key={index} onClick={() => removeVersion('ios', item)}>
                                                <FlexRowContainer style={{alignItems: 'center'}}>
                                                    <IconImage src={IconAsset.Trashcan}></IconImage>
                                                    <p style={styles.availableVersionText}>{item}</p>
                                                </FlexRowContainer>
                                            </CardPrimary>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </FlexRowContainer>
            }
        </FlexColumnContainer>
    )
}

const styles = {
    hero: {
        display: 'flex'
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '700px'
    },
    content: {
        maxWidth: '700px'
    },
    availableVersionWrapper: {
        margin: '0 10px'
    },
    availableVersionText: {
        marginLeft: '10px'
    },
    availableVersionList: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '350px',
        minHeight: '350px',
        overflowY: 'scroll',
    },
    availableVersionBottom: {
        display: 'flex',
        alignItems: 'center'
    },
    loadingWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '50vh',
        height: '50px',
        padding: '20px'
    },
    loading: {
        width: '50px',
        height: '50px'
    }
}