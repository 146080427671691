import React, { useEffect, useState,  } from "react"
import { Route, Routes, useNavigate, useLocation } from "react-router"
import { IconAsset } from "../assets/IconAsset"
import { FlexRowContainer } from '../components/Container'
import { Inputs } from '../components/Inputs'
import { UserModel } from '../services/database/models/User.Model'
import { BILIS_colors } from '../values/String'
import { ApplicationVersioning } from './dashboard-pages/ApplicationVersioning'
import { Categories } from "./dashboard-pages/Categories"
import { DashboardContent } from './dashboard-pages/Dashboard.Content'
import { Feedback } from "./dashboard-pages/Feedback"
import { RegisterBarangay } from "./dashboard-pages/RegisterBarangay"
import { ServerBuilds } from "./dashboard-pages/ServerBuild"
import { Services } from "./dashboard-pages/Services"
import { GenesisAdmin } from "./dashboard-pages/GenesisAdmin"
import { Authentication } from "./dashboard-pages/Authentication"
import { Badges } from "./dashboard-pages/badges-pages/Badges"
import { UserProfileFeeds } from "./dashboard-pages/UserProfileFeeds"
import { BarangayRegistration } from "./dashboard-pages/barangay-registration-pages/BarangayRegistration"
import { BarangayRegistrationOverview } from "./dashboard-pages/barangay-registration-pages/BarangayRegistrationOverview"
import { CompareArray } from "../utils/CompareArray"
import { Profile } from "./dashboard-pages/Profile"
import { ErrorLogs } from "./dashboard-pages/error-log-pages/ErrorLogs"
import { ErrorLogOverview } from "./dashboard-pages/error-log-pages/ErrorLogOverview"
import { FormTemplates } from "./dashboard-pages/form-templates/FormTemplates"
import { FormTemplateOverview } from "./dashboard-pages/form-templates/FormTemplateOverview"


let navBarItems = [
    {
        name: 'Dashboard',
        urlParams: '/dashboard/content',
        allowedRoles: ['developer', 'management']
    },
    {
        name: 'Application Versioning',
        urlParams: '/dashboard/application-versioning',
        allowedRoles: ['developer']
    },
    // {
    //     name: 'Genesis Admin',
    //     urlParams: '/dashboard/genesis-admin',
    //     allowedRoles: ['developer']
    // },
    {
        name: 'Authentication',
        urlParams: '/dashboard/authentication',
        allowedRoles: ['developer']
    },
    // {
    //     name: 'Badges',
    //     urlParams: '/dashboard/badges/',
    //     allowedRoles: ['developer']
    // },
    {
        name: 'User Feedbacks',
        urlParams: '/dashboard/feedbacks',
        allowedRoles: ['developer']
    },
    {
        name: 'Categories',
        urlParams: '/dashboard/categories/',
        allowedRoles: ['developer']
    },
    {
        name: 'User Profile Feeds',
        urlParams: '/dashboard/user-profile-feeds',
        allowedRoles: ['developer', 'management']
    },
    {
        name: 'Error Logs',
        urlParams: '/dashboard/error-logs',
        allowedRoles: ['developer', 'management']
    },
    {
        name: 'Services',
        urlParams: '/dashboard/services',
        allowedRoles: ['developer']
    },
    {
        name: 'Server Builds',
        urlParams: '/dashboard/server-builds',
        allowedRoles: ['developer', 'management']
    },
    {
        name: 'Barangay Registration',
        urlParams: '/dashboard/barangay-registrations',
        allowedRoles: ['developer', 'superadmin']
    },
    // {
    //     name: 'Manual Barangay Registration',
    //     urlParams: '/dashboard/manual-barangay-registration',
    //     allowedRoles: ['developer', 'superadmin']
    // },
    {
        name: 'Form Templates',
        urlParams: '/dashboard/form-templates',
        allowedRoles: ['developer', 'superadmin']
    },
    {
        name: 'Profile',
        urlParams: '/dashboard/profile',
        allowedRoles: ['developer', 'superadmin', 'management']
    },
]

export function Dashboard() {
    const location = useLocation()
    const navigate = useNavigate()
    const [currentUser, setCurrentUser] = useState()
    const [isLoading, setLoading] = useState(true)
    const [ navBarItem, setNavBarItem ] = useState()
    // Initialize
    async function Init() {
        try {
            const user = await UserModel.getCurrentUser()
            setCurrentUser(user.data.data)
            if (!user) return navigate('/pin')
            await ContentManager(user.data.data)
        } catch (error) {
            if (error === 'ACCESS_TOKEN_INVALID')
                return navigate('/pin')
        }
    }

    function navigateOptions(evt) {
        const text = evt.target.innerText
        navBarItem.forEach((item) => {
            if (item.name == text) {
                navigate(item.urlParams)
            }
        })
    }

    async function ContentManager(userData) {
        let navBarMemo = []
        const userRoles = userData.attributes.roles

        await navBarItems.reduce(async (promise, item, index) => {
            await promise
            const compareRole = await CompareArray(item.allowedRoles, userRoles)

            if (compareRole.hasSimilarities) {
                navBarMemo.push(item)
            }
        }, Promise.resolve())

        setNavBarItem(navBarMemo)
        //navigate(navBarMemo[0].urlParams)
        setLoading(false)
    }

    useEffect(() => {
        Init()
    }, [])


    //#region Components
    function NavBar() {
        return navBarItem.map((item, index) => {
            if (location.pathname.includes(item.urlParams)) {
                return ( 
                    <p key={index} style={styles.navigationBarButtonSelected}>{item.name}</p>
                ) 

            }
            return ( 
                <p key={index} style={styles.navigationBarButton} onClick={(evt) => navigateOptions(evt)}>{item.name}</p>
            ) 
        })
    }
    //#endregion

    return (
        <div>
            <FlexRowContainer>
                <div style={styles.navigationBar}>
                    <div style={styles.bannerContent}>
                        <FlexRowContainer style={{alignItems: 'center', padding: '10px'}}>
                            <img src={IconAsset.BILISLogo} style={{width: '100px', paddingRight: '10px'}}/>
                            <h3 style={{...Inputs.textPrimary }}>Developers</h3>
                        </FlexRowContainer>
                    </div>
                    <div style={styles.navigationBarContent}>
                        {
                            !isLoading && <NavBar />      
                        }
                    </div>
                </div>
                <div style={styles.spotlight}>
                    <Routes> 
                        <Route path="/content/*" element={<DashboardContent />}/>
                        <Route path="/application-versioning" element={<ApplicationVersioning />}/>
                        <Route path="/genesis-admin" element={<GenesisAdmin />}/>
                        <Route path="/authentication" element={<Authentication />}/>
                        <Route path="/badges/*" element={<Badges />}/>
                        <Route path="/categories/*" element={<Categories />}/>
                        <Route path="/user-profile-feeds/*" element={<UserProfileFeeds />}/>
                        <Route path="/feedbacks" element={<Feedback />}/>
                        <Route path="/services" element={<Services />}/>
                        <Route path="/server-builds" element={<ServerBuilds />}/>
                        <Route path="/barangay-registrations" element={<BarangayRegistration />}/>
                        <Route path="/manual-barangay-registration" element={<RegisterBarangay />}/>
                        <Route path="/barangay-registration/*" element={<BarangayRegistrationOverview />}/>
                        <Route path="/profile" element={<Profile />}/>
                        <Route path="/error-logs" element={<ErrorLogs />}/>
                        <Route path="/error-log/*" element={<ErrorLogOverview />}/>
                        <Route path="/form-templates" element={<FormTemplates />}/>
                        <Route path="/form-template" element={<FormTemplateOverview />}/>
                    </Routes> 
                </div>
            </FlexRowContainer>
        </div>
    )
}

const styles = {
    content: {
        display: 'flex',
    },
    bannerContent: {
        display: 'flex',
        padding: '10px'
    },
    navigationBar: {
        width: '200px',
        marginLeft: '20px',
    },
    navigationBarContent: {
        width: '230px',
        height: '85vh',
        paddingRight: '20px',
        overflowY: 'scroll'
    },
    navigationBarButtonSelected: {
        backgroundColor: 'whitesmoke',
        color: BILIS_colors.accent,
        padding: '15px',
        margin: '10px 0px',
        borderRadius: '5px',
        fontWeight: 'bold',
        cursor: 'default',
        userSelect: 'none',
    },
    navigationBarButton: {
        padding: '15px',
        borderRadius: '10px',
        margin: '10px 0px',
        cursor: 'pointer',
        userSelect: 'none'
    },
    spotlight: {
        marginTop: '15px',
        marginLeft: '50px',
        width: '100%',
        height: '80vh'
    }
}
