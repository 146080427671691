import axios from "axios"
import { UserModel } from "./User.Model"
import { Server } from "../../../values/Server"

class FormTemplate {
    async createFormTemplate(name, variable) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${Server.API_ENDPOINT}/form-template`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
                data: {
                    data: {
                        name: name,
                        variable: variable,
                        defaultContents: [],
                        components: [
                            [
                                {
                                    "type": "title",
                                    "isRequired": false,
                                    "value": name
                                }
                            ]
                        ]
                    }
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
    async getFormTemplates(pagination, addedQuery = '') {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/form-templates?pagination[page]=${pagination.page}&pagination[limit]=${pagination.limit}${addedQuery}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
    async getFormTemplateById(id, addedQuery = '') {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/form-template/${id}${addedQuery}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
    async updateFormTemplateById(id, data, addedQuery = '') {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'PATCH',
                url: `${Server.API_ENDPOINT}/form-template/${id}${addedQuery}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
                data: {
                    data: data
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
    async deleteFormTemplateById(id) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'DELETE',
                url: `${Server.API_ENDPOINT}/form-template/${id}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
}

export const FormTemplateModel = new FormTemplate()