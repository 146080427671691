import axios from "axios"
import { Server } from "../../../values/Server"

class User {
    /**
     * @description The function getCurrentUser, takes the accessToken from the localStorage, then returns a Promise that 
     * takes a resolve and a reject callback. The promise is fulfilled if the accessToken is stored in the localStorage. 
     * Otherwise, the promise is rejected. If the promise is fulfilled, the user info is returned with the accessToken attached. 
     * Otherwise, if the promise is rejected, the accessToken is checked for the TokenExpiredError. If the accessToken has expired, 
     * a new one is requested by the server to replace the old one. If the accessToken is invalid, the promise is rejected.
     * @returns 
     */
    getCurrentUser() {
        const accessToken = localStorage.getItem('accessToken')
        return new Promise(async (resolve, reject) => {
            if (!accessToken) reject('NO_ACCESS_TOKEN')
            await axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/user/me`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve({ ...snapshot, accessToken: accessToken })
            }).catch(async error => {
                const response = error.response.data
                // Refresh
                if (response?.error?.name == 'TokenExpiredError')
                    await axios({
                        method: 'POST',
                        url: `${Server.API_ENDPOINT}/user/me/refresh`,
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    }).then(snapshot => {
                        localStorage.setItem('accessToken', snapshot.data.data.attributes.accessToken)
                        resolve(this.getCurrentUser())
                    }).catch(error => {
                        const response = error.response.data
                        if (response?.error?.name == 'ACCESS_TOKEN_INVALID')
                            reject('ACCESS_TOKEN_INVALID')
                    })

                // if (response?.error?.name == 'TOKEN_UNAVAILABLE') {
                //     alert('Device was sign out')
                //     localStorage.removeItem("authToken")
                //     localStorage.removeItem("accessToken")
                //     window.location.href = '/login'
                // }
            })
        })
    }
    /**
     * @description Gets the access token from local storage.
     * @returns 
     */
    async getAccessToken() {
        await this.getCurrentUser()
        return localStorage.getItem('accessToken')
    }
    /**
     * @description returns the authentication token that is stored in local storage
     * @returns 
     */
    getAuthenticationToken() {
        return localStorage.getItem('authToken')
    }
    /**
     * @description This function logs out the user from the app and removes the `authToken` and `accessToken` from the local storage.
     */
    logout() {
        localStorage.removeItem('authToken')
        localStorage.removeItem('accessToken')
    }
    loginQRCode() {
        // return new Promise((resolve, reject) => {
        //     axios({
        //         method: 'POST',
        //         url: `${Server.API_ENDPOINT}/user/login/qr`
        //     }).then(snapshot => {
        //         return resolve(snapshot.data)
        //     }).catch(error => {
        //         return reject(error.response.data)
        //     })
        // })
    }
    isUserExists(email) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/user/exists/${email}`
            }).then(snapshot => {
                resolve(snapshot.data.data)
            }).catch(error => {
                reject(error)
            })
        })
    }
    getUserByIdentifier(identifier) {
        return new Promise(async (resolve, reject) => {
            const accessToken = await this.getAccessToken()
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/user/by-identifier/${identifier}`,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data.data)
            }).catch(error => {
                reject(error)
            })
        })
    }
    updateUserRole(email, barangayId, role) {
        return new Promise((resolve, reject) => {
            const accessToken = localStorage.getItem('accessToken')
            axios({
                method: 'PATCH',
                url: `${Server.API_ENDPOINT}/user/identifier/${email}/role/${barangayId}/${role.toLowerCase()}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data.data)
            }).catch(error => {
                reject(error)
            })
        })
    }
    getAllUsers(page, limit, additionalQuery = "") {
        const accessToken = localStorage.getItem('accessToken')

        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/users?pagination[page]=${page}&pagination[limit]=${limit}&sort[createdAt]=-1${additionalQuery}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(async error => {
                reject(error)
            })
        })
    }
    getUserByEmail(email) {
        const accessToken = localStorage.getItem('accessToken')
        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/user/by-identifier/${email}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(async error => {
                reject(error)
            })
        })
    }
    getUser(id) {
        const accessToken = localStorage.getItem('accessToken')
        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/user/${id}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(async error => {
                reject(error)
            })
        })
    }
    updateUser(userId, data, meta) {
        const accessToken = localStorage.getItem('accessToken')
        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'PATCH',
                url: `${Server.API_ENDPOINT}/user/${userId}`,
                data: {
                    data: data,
                    meta: meta
                },
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(async error => {
                reject(error)
            })
        })
    }
}

export const UserModel = new User()