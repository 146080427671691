import React, { useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router"
import { FlexColumnContainer, FlexRowContainer } from "../../components/Container"
import { TypographyHeader2 } from "../../components/Typography"
import DataGrid from "../../components/DataGrid"
import { UserProfileFeedModel } from "../../services/database/models/UserProfileFeed.Model"
import { Chip, Pagination } from "@mui/material"
import ModalDefault from "../../components/Modal"
export function UserProfileFeeds() {
    const [addedQuery, setAddedQuery] = useState()
    const [userProfileFeedsMeta, setUserProfileFeedsMeta] = useState()
    const [userProfileFeeds, setUserProfileFeeds] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [modal, setModal] = useState({
        isOpen: false
    })
    const [isLoading, setLoading]= useState(true)
    //#endregion
    async function getUserProfileFeeds(page) {
        const fetchUserProfileFeeds = await UserProfileFeedModel.getUserProfileFeeds(page, 10, addedQuery)
        let collectedData = []
        await fetchUserProfileFeeds.data.reduce(async (promise, snapshot, index) => {
            await promise
            delete snapshot.attributes.updatedAt
            collectedData.push({ id: snapshot.id, ...snapshot.attributes })
        }, Promise.resolve())
        setUserProfileFeeds(collectedData)
        setUserProfileFeedsMeta(fetchUserProfileFeeds.meta)
        setLoading(false)
    }

    useEffect(() => {
        getUserProfileFeeds(1)
    }, [addedQuery])

    return (
        <div>
            {
                modal.isOpen &&
                    <ModalDefault isOpen={modal.isOpen} title={modal.title} description={modal.description} onCancel={() => setModal({ isOpen: false })} onAgree={modal.onAgree}></ModalDefault>
            }
            <TypographyHeader2>
                User Profile Feeds
            </TypographyHeader2>
            <FlexColumnContainer>
                <DataGrid
                    isLoading={isLoading}
                    headers={[
                        'label',
                        'value',
                        'type',
                        'createdAt',
                    ]}
                    items={userProfileFeeds}
                    filterOptions={{
                        attributes: [
                            'label',
                            'value',
                            'type'
                        ]
                    }}
                    onAddSubmit={async ({ label, value, type }) => {
                        await UserProfileFeedModel.addUserProfileFeed({
                            label,
                            value,
                            type
                        })
                        getUserProfileFeeds(1)
                    }}
                    onFilterSubmit={(content) => {
                        setAddedQuery(`&filter[${content.attribute}]=${content.value}`)
                    }}
                    onFilterClear={() => {
                        setAddedQuery()
                    }}
                    onItemRemoved={async (id, index, content) => {
                        setModal({
                            isOpen: true,
                            title: "Remove Form Template",
                            description: `Are you sure you want to remove this user profile feed labeled '${content.label}'?`,
                            onAgree: async () => {
                                await UserProfileFeedModel.removeUserProfileFeed(id)
                                setModal({
                                    isOpen: false
                                })
                                getUserProfileFeeds(currentPage)
                            }
                        })
                    }}
                />
            </FlexColumnContainer>
            <FlexColumnContainer style={{ alignItems: "center" }}>
                {
                    userProfileFeedsMeta?.pagination.totalPages > 0 &&
                        <Pagination
                            count={userProfileFeedsMeta?.pagination.totalPages}
                            hidePrevButton={false}
                            onChange={async (data, index) => {
                                setCurrentPage(index)
                                getUserProfileFeeds(index)
                            }}
                        >
                        </Pagination>
                }
            </FlexColumnContainer>
        </div>
    )
}

const styles = {
    spotlight: {
        marginTop: '15px',
        width: '100%',
        height: '80vh'
    },
}