
import { Route, Routes, useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { FlexColumnContainer, FlexRowContainer } from "../../../components/Container";
import { TypographyHeader2 } from "../../../components/Typography";
import { NavBar } from "../../../components/NavigationBar";
import { BadgeLists } from "./BadgeLists";
import { GiveUsers } from "./GiveUsers";

export function Badges() {
    const location = useLocation()
    const navigate = useNavigate()

    const [navBarItem, setNavBarItem] = useState([
        {
            name: 'Badge Lists',
            urlParams: '/dashboard/badges/lists',
        },
        {
            name: 'Give Users',
            urlParams: '/dashboard/badges/give-users',
        }
    ])

    // Initialize
    async function Init() {
        navigate('/dashboard/badges/lists')
        //setLoading(false)
    }


    // Use Effect
    useEffect(() => {
        Init()
    }, [])
    return (
        <FlexColumnContainer>
            <TypographyHeader2>Badges</TypographyHeader2>
            <FlexRowContainer>
                <NavBar navigate={navigate} location={location} navigationItems={navBarItem} />
            </FlexRowContainer>
            <FlexColumnContainer>
                <Routes>
                    <Route path="/lists" element={<BadgeLists />} />
                    <Route path="/give-users" element={<GiveUsers />} />
                </Routes>
            </FlexColumnContainer>
        </FlexColumnContainer>
    )
}