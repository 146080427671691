import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { FlexColumnContainer } from "../../../components/Container"
import { TypographyHeader2 } from "../../../components/Typography"
import DataGrid from "../../../components/DataGrid"
import { BarangayRegistrationModel } from "../../../services/database/models/BarangayRegistration.Model"
import { Pagination } from "@mui/material"
export function BarangayRegistration() {
    const navigate = useNavigate()
    const [barangayRegistrations, setBarangayRegistrations] = useState()
    const [barangayRegistrationsMeta, setBarangayRegistrationsMeta] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setLoading] = useState(true)

    async function getBarangayRegistrations(page) {
        const fetchBarangayRegistrations = await BarangayRegistrationModel.getActiveRegistrations({
            page: 1,
            limit: 10
        })
        let collectedData = []
        await fetchBarangayRegistrations.data.reduce(async (promise, snapshot, index) => {
            await promise
            delete snapshot.attributes.updatedAt
            const regionName = snapshot.attributes.region.Name
            const provinceName = snapshot.attributes.province.Name
            const municipalName = snapshot.attributes.municipal.Name
            const barangayName = snapshot.attributes.barangay.Name

            collectedData.push({
                id: snapshot.id, 
                regionName,
                municipalName,
                provinceName,
                barangayName,
                ...snapshot.attributes, 
            })

        }, Promise.resolve())
        setBarangayRegistrations(collectedData)
        setBarangayRegistrationsMeta(fetchBarangayRegistrations.meta)
        setLoading(false)
    }
    //#endregion
    useEffect(() => {
        getBarangayRegistrations()
    }, [])
    return (
        <div>
            <TypographyHeader2>
                Barangay Registration
            </TypographyHeader2>
            <FlexColumnContainer>
                <DataGrid
                    isLoading={isLoading}
                    headers={[
                        'id',
                        'regionName',
                        'provinceName',
                        'municipalName',
                        'barangayName',
                        'ownerEmail',
                        'createdAt',
                    ]}
                    items={barangayRegistrations}
                    filterOptions={{
                        attributes: [
                            'id',
                            'ownerEmail',
                        ]
                    }}
                    onItemClick={async (item, index) => {
                        const chosenItem = barangayRegistrations[index]
                        navigate(`/dashboard/barangay-registration?id=${chosenItem.id}`)
                    }}
                    onClickRefresh={() => getBarangayRegistrations(1)}
                />
            </FlexColumnContainer>
            <FlexColumnContainer style={{ alignItems: "center" }}>
                {
                    barangayRegistrationsMeta?.pagination.totalPages > 0 &&
                        <Pagination
                            count={barangayRegistrationsMeta?.pagination.totalPages}
                            hidePrevButton={false}
                            onChange={async (data, index) => {
                                setCurrentPage(index)
                                getBarangayRegistrations(index)
                            }}
                        >
                        </Pagination>
                }
            </FlexColumnContainer>
        </div>
    )
}
