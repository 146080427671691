import { useEffect, useState } from "react";
import { FormTemplateModel } from "../../../services/database/models/FormTemplate.Model";
import {
  TypographyDefault,
  TypographyGray,
  TypographyHeader2,
} from "../../../components/Typography";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  FlexColumnContainer,
  FlexRowContainer,
} from "../../../components/Container";
import {
  IconImage,
  LoadingComponent,
  ToggleSwitch,
} from "../../../components/Miscs";
import { IconAsset } from "../../../assets/IconAsset";
import { ButtonDefault, ButtonWhite } from "../../../components/Buttons";
import { FormComponent } from "./FormComponentEnumerator";
import { FormControl, InputLabel, MenuItem, Modal, Pagination, Select } from "@mui/material";
import { InputPrimary } from "../../../components/Inputs";
import ModalDefault from "../../../components/Modal";
import { BILIS_colors } from "../../../values/String";

export function FormTemplateOverview() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [formTemplate, setFormTemplate] = useState();
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();

  // For components
  const [componentPage, setComponentPage] = useState(1);
  const [currentComponentList, setCurrentComponentList] = useState();
  const [modal, setModal] = useState({
    isOpen: false
  })

  const [description, setDescription] = useState()

  async function getFormTemplate() {
    const formTemplateId = searchParams.get("id");
    const getFormTemplate = await FormTemplateModel.getFormTemplateById(
      formTemplateId
    );
    setFormTemplate(getFormTemplate.data);
    setCurrentComponentList(getFormTemplate.data.attributes.components[componentPage - 1]);
    setLoading(false);
  }

  async function onComponentChange(index, value) {
    currentComponentList[index] = value;
  }

  async function onPublish() {
    let finalComponent = formTemplate.attributes.components;

    // Change the current component into the new one
    finalComponent[componentPage - 1] = currentComponentList;

    // Scan all the components for keys
    let defaultContents = {};
    await finalComponent.reduce(async (promise, snapshot, index) => {
      await promise;

      await snapshot.reduce(async (promise, dataSnapshot) => {
        await promise;
        if (dataSnapshot.type === "textbox") {
          defaultContents = {
            ...defaultContents,
            [dataSnapshot.variable]: {
              type: 'string'
            }
          }

          if (dataSnapshot.isRequired) {
            defaultContents = {
              ...defaultContents,
              [dataSnapshot.variable]: {
                type: 'string',
                required: true
              }
            }
          }
        }
      }, Promise.resolve());
    }, Promise.resolve());

    await FormTemplateModel.updateFormTemplateById(formTemplate.id, {
      defaultContents: defaultContents,
      components: finalComponent,
    });


    if (description) {
      await FormTemplateModel.updateFormTemplateById(formTemplate.id, {
        description: description
      })
    }
    alert("Saved Form Template")
  }

  async function addPage() {

    setModal({
      isOpen: true,
      title: "Add Page",
      description: "Add an additional page for the form template?",
      onAgree: async () => {
        let finalComponent = formTemplate.attributes.components;

        finalComponent.push([])
        await FormTemplateModel.updateFormTemplateById(formTemplate.id, {
          components: finalComponent,
        });
    
        setModal({
          isOpen: false
        })
        setComponentPage(1)
        getFormTemplate()
      }
    })
  }


  async function deletePage() {
    setModal({
      isOpen: true,
      title: "Delete Page",
      description: "Are you sure you want to delete this page?",
      onAgree: async () => {
        let finalComponent = formTemplate.attributes.components;
        finalComponent.splice(componentPage - 1, 1)
        await FormTemplateModel.updateFormTemplateById(formTemplate.id, {
          components: finalComponent,
        });

        setModal({
          isOpen: false
        })
        setComponentPage(1)
        getFormTemplate()
      }
    })

  }


  async function changeOrder(componentIndex, value) {
    onPublish()
    const componentList = formTemplate.attributes.components[componentPage - 1]

    function swapElements(arr, i1, i2) {
      // Step 1
      let temp = arr[i1];

      // Step 2
      arr[i1] = arr[i2];

      // Step 3
      arr[i2] = temp;
    }
    swapElements(componentList, componentIndex, value)
    let finalComponent = formTemplate.attributes.components;
    finalComponent[componentPage - 1] = componentList
    setCurrentComponentList([])
    await FormTemplateModel.updateFormTemplateById(formTemplate.id, {
      components: finalComponent,
    });
    getFormTemplate()
  }


  async function setPublic(id) {
    await FormTemplateModel.updateFormTemplateById(id, {
      visibility: "public"
    })
    getFormTemplate()
  }

  async function setPrivate(id) {
    await FormTemplateModel.updateFormTemplateById(id, {
      visibility: "private"
    })
    getFormTemplate()
  }

  useEffect(() => {
    getFormTemplate();
  }, []);

  useEffect(() => {
    setCurrentComponentList([]);
    getFormTemplate();
  }, [componentPage]);


  useEffect(() => {

  }, [currentComponentList])
  return (
    <div>
      {
        modal.isOpen &&
        <ModalDefault isOpen={true} title={modal.title} description={modal.description} onCancel={() => setModal({ isOpen: false })} onAgree={modal.onAgree}></ModalDefault>
      }

      <FlexRowContainer
        style={{ alignContent: "center", alignItems: "center" }}
      >
        <FlexColumnContainer
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/form-templates");
          }}
        >
          <img
            src={IconAsset.BackButton}
            style={{ width: "35px", height: "35px" }}
          ></img>
        </FlexColumnContainer>
        <div style={{ marginLeft: 10 }}></div>
        <TypographyHeader2>Form Template Overview</TypographyHeader2>
      </FlexRowContainer>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <FlexColumnContainer
            style={{
              padding: "20px",
              overflowY: "scroll",
              height: "90vh",
              marginBottom: "80px",
            }}
          >
            <TypographyDefault style={{ fontWeight: "bold" }}>
              Id:
            </TypographyDefault>
            <TypographyGray>{formTemplate.id}</TypographyGray>
            <TypographyDefault style={{ fontWeight: "bold" }}>
              Name:
            </TypographyDefault>
            <TypographyGray>{formTemplate.attributes.name}</TypographyGray>
            <TypographyDefault style={{ fontWeight: "bold" }}>
              Variable:
            </TypographyDefault>
            <TypographyGray>{formTemplate.attributes.variable}</TypographyGray>
            <TypographyDefault style={{ fontWeight: "bold" }}>
              Created At:
            </TypographyDefault>
            <TypographyGray>
              {new Date(formTemplate.attributes.createdAt).toLocaleString()}
            </TypographyGray>
            <TypographyDefault style={{ fontWeight: "bold" }}>
              Updated At:
            </TypographyDefault>
            <TypographyGray>
              {new Date(formTemplate.attributes.updatedAt).toLocaleString()}
            </TypographyGray>
            <TypographyDefault style={{ fontWeight: "bold" }}>
              Visibility:
            </TypographyDefault>
            {
              formTemplate.attributes.visibility === 'public'
                     ?
                     <FlexColumnContainer style={{ flexDirection: "row", backgroundColor: "#8856E5", width: "80px", padding: 10, borderRadius: 10, alignContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => setPrivate(formTemplate.id)}>
                         <img src={IconAsset.ChecklistWhite} style={{ width: "20px" }}></img>
                         <div style={{ width: 10 }}></div>
                         <p style={{ fontWeight: "bold", color: "white", textAlign: "center", userSelect: "none" }}>Public</p>
                     </FlexColumnContainer>
                     :
                     <FlexColumnContainer style={{ flexDirection: "row", backgroundColor: BILIS_colors.secondary, width: "80px", padding: 10, borderRadius: 10, alignContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => setPublic(formTemplate.id)}>
                         <img src={IconAsset.CheckmarkWhite} style={{ width: "20px" }}></img>
                         <div style={{ width: 10 }}></div>
                         <p style={{ fontWeight: "bold", color: "white", textAlign: "center", userSelect: "none" }}>Private</p>
                     </FlexColumnContainer>
            }
            <TypographyDefault style={{ fontWeight: "bold" }}>
              Description:
            </TypographyDefault>
            <InputPrimary value={formTemplate.attributes.description} onChange={(evt) => {
              setDescription(evt.target.value)
            }}>
            </InputPrimary>
            <TypographyDefault style={{ fontWeight: "bold" }}>
              Components:
            </TypographyDefault>

            <FlexColumnContainer>
              <FlexRowContainer style={{ alignItems: "center" }}>
                <TypographyDefault>{`Page ${componentPage} / ${formTemplate.attributes.components.length}`}</TypographyDefault>
                <FlexColumnContainer
                  style={{ padding: "10px" }}
                ></FlexColumnContainer>

                <FlexColumnContainer
                  style={{ padding: "10px" }}
                ></FlexColumnContainer>
                <ButtonDefault
                  value={"Publish"}
                  onClick={onPublish}
                ></ButtonDefault>
              </FlexRowContainer>
              <div style={{ height: "20px" }}></div>

              <FlexColumnContainer style={{ alignItems: "center" }}>
                {
                  <Pagination
                    count={formTemplate.attributes.components.length}
                    hidePrevButton={false}
                    onChange={async (data, index) => {
                      setComponentPage(index);
                    }}
                  ></Pagination>
                }
              </FlexColumnContainer>
              <div style={{ height: "20px" }}></div>


              {
                currentComponentList &&
                currentComponentList.map((value, index) => {
                  return (
                    <FlexColumnContainer
                      key={index}
                      style={{
                        backgroundColor: "whitesmoke",
                        marginBottom: "10px",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      <FormComponent
                        index={index}
                        placeholder={value?.placeholder}
                        value={value?.value}
                        placeholderValue={value?.placeholderValue}
                        variable={value?.variable}
                        type={value?.type}
                        isRequired={value?.isRequired}
                        array={value?.array}
                        componentOnChange={(index, value) => {
                          onComponentChange(index, value)
                        }}
                        tipMessage={value?.tipMessage}
                      ></FormComponent>
                      <div style={{ height: "20px" }}></div>
                      <FlexRowContainer
                        style={{ borderTop: "1px solid lightgray" }}
                      >
                        <IconImage
                          src={IconAsset.Trashcan}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const memo = currentComponentList.filter(
                              (value, currentIndex) => currentIndex !== index
                            );
                            setCurrentComponentList(memo);
                          }}
                        ></IconImage>
                        <FlexColumnContainer style={{ margin: '10px' }}>
                          <FormControl>
                            <InputLabel id="type-select-order">Order</InputLabel>
                            <Select
                              labelId="type-select-order"
                              id="type-select-id"
                              label="Type"
                              placeholder="Genesis Admins"
                              defaultValue={"textbox"}
                              value={index}
                              onChange={(evt) => {
                                changeOrder(index, evt.target.value)
                              }}
                            >
                              {
                                currentComponentList.map((value, index) => {
                                  return (
                                    <MenuItem key={index} value={index}>
                                      {index}
                                    </MenuItem>
                                  )
                                })
                              }
                            </Select>
                          </FormControl>
                        </FlexColumnContainer>

                      </FlexRowContainer>
                    </FlexColumnContainer>
                  );
                })}
            </FlexColumnContainer>

            <FlexRowContainer>
              <IconImage
                src={IconAsset.Plus}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setCurrentComponentList([
                    ...currentComponentList,
                    {
                      type: "textbox",
                      isRequired: false,
                      placeholder: "",
                      placeholderValue: "",
                      variable: "",
                    },
                  ]);
                }}
              ></IconImage>
              <ButtonDefault value={"Add Page"} onClick={addPage}></ButtonDefault>
              <div style={{ marginLeft: '10px' }}></div>
              <ButtonWhite value={"Delete Page"} onClick={deletePage}></ButtonWhite>
            </FlexRowContainer>
            <div style={{ marginTop: '150px' }}></div>

          </FlexColumnContainer>
        </>
      )}
    </div>
  );
}
