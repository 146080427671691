import { BILIS_colors } from "../values/String"
import { Button } from "@mui/material"
import { motion } from "framer-motion"
export const Buttons = {
    buttonEnabled: {
        border: 'none',
        borderRadius: '5px',
        padding: '10px',
        color: 'white',
        fontWeight: 'bold',
        cursor: 'pointer',
        background: BILIS_colors.primary
    },
    buttonDisabled: {
        border: 'none',
        borderRadius: '5px',
        padding: '10px',
        color: 'white',
        fontWeight: 'bold',
        background: 'lightgray'
    }
}


export function ButtonDefault({ style, value, onClick }) {
    return (
        <Button
            disabled={false}
            variant="contained"
            sx={{
                textTransform: 'none',
                backgroundColor: BILIS_colors.primary,
                ':hover': {
                    backgroundColor: BILIS_colors.primary
                }
            }}
            onClick={onClick}
        >
            {value}
        </Button>
    )
}




export function ButtonDisabled({ style, value }) {
    return (
        <Button disabled={true} variant="contained">{value}</Button>
    )
}

export function ButtonWhite({ style, type, value, onClick }) {
    return (
        <motion.button 
            type='submit' 
            style={{
                border: '1px solid',
                borderColor: BILIS_colors.primary,
                borderRadius: '5px',
                padding: '10px',
                color: BILIS_colors.primary,
                fontWeight: 'bold',
                cursor: 'pointer',
                background: '#FFF',
                textAlign: 'center',
                ...style
            }}
            onClick={onClick}
            whileHover={{
                backgroundColor: BILIS_colors.primary,
                color: '#FFF'
            }}
            
        >
            {value}
        </motion.button>
    )
}