import { PlaceholderField, PlaceholderValueField, TipMessageField, VariableField } from "./FormComponent"

export const CalendarPicker = (placeholder, placeholderValue, variable, tipMessage, onChange) => {
    return <>
        {
            PlaceholderField(placeholder, onChange)
        }
        {
            PlaceholderValueField(placeholderValue, onChange)
        }
        {
            VariableField(variable, onChange)
        }
        {
            TipMessageField(tipMessage, onChange)
        }
    
    </>
}