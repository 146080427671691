import Chart from "react-apexcharts";

export const ChartContainers = {
    default: {
        width: '500px'
    }
}
export function DonutChart({ series, labels }) {
    const options = {
        labels: labels,
        legend: {
            position: 'right'
        }
    }        
    return (
        <div style={ChartContainers.default}>
            <Chart options={options} series={series} type="donut" />
        </div>
    )
}
export function ColumnChart({ series, width, height}) {
    const options = {}   
    return (
        <div style={{ width: width, height: height }}>
            <Chart options={options} series={series} type="bar" />
        </div>
    )
}