import { useEffect, useState } from "react";
import { TypographyHeader2, TypographyDefault, TypographyGray } from "../../components/Typography";
import { HorizontalSpacing, LoadingComponent, ToggleSwitch, VerticalSpacing } from "../../components/Miscs";
import { FlexRowContainer } from "../../components/Container";
import { ServiceModel } from "../../services/database/models/Service.Model";
import { CapitalizeFirstLetter } from "../../utils/StringHelper";

export function Services() {
    const [ services, setServices ] = useState([])
    const [ isLoading, setLoading ] = useState(true)
    // The function Init() is an async function that calls the getAllServices method and
    // sets the services to getServices and sets the loading to false.
    async function Init() {
        const getServices = await ServiceModel.getAllServices()
        setServices(getServices)
        setLoading(false)
    }
    // The function toggle takes two arguments service and state, and updates the state of the service.
    async function toggle(service, state) {
        await ServiceModel.updateService(service, state)
    }
    useEffect(() => {
        Init()
    }, [])
    useEffect(() => {}, [services])
    return (
        <div>
            <TypographyHeader2>
                Services
            </TypographyHeader2>
            <TypographyGray>
                The purpose of this switch is to provide a convenient way of enabling or disabling services or endpoints as needed.
                <br></br>
                Note that turning off a service will make it unusable until it is turned on again.
            </TypographyGray>
            {
                !isLoading 
                    ?
                        services && services.map((data, index) => {
                            return (
                                <div key={index}>
                                    <VerticalSpacing />
                                    <FlexRowContainer style={{alignItems: 'center'}}>
                                        <ToggleSwitch defaultState={!data.attributes.isMaintenance} onClick={state => toggle(data.attributes.name, state)} />
                                        <HorizontalSpacing />
                                        <TypographyDefault>
                                            {
                                                `Endpoints for ${CapitalizeFirstLetter(data.attributes.name)}` 
                                            } 
                                        </TypographyDefault>
                                    </FlexRowContainer>
                                </div>
                            )
                        })
                    : <LoadingComponent />
                    
            }
        </div>
    )
}