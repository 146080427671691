import axios from 'axios'
import React, { useEffect, useState } from "react"
import QRCode from 'react-qr-code'
import { useNavigate } from "react-router"
import { ButtonDefault, ButtonDisabled } from "../components/Buttons"
import { InputPrimary } from "../components/Inputs"
import { HorizontalSpacing, LoadingComponent, VerticalSpacing } from "../components/Miscs"
import { BILIS_headers } from '../values/String'
import { Server } from '../values/Server'
import { IconAsset } from '../assets/IconAsset'
import { FlexColumnContainer, FlexRowContainer } from '../components/Container'
import { TypographyError, TypographyGray } from '../components/Typography'
import { UserModel } from '../services/database/models/User.Model'
import { socketIO } from '../services/socket/Socket.IO'
import { Sockets } from '../values/Sockets'
import { CardPrimary } from '../components/Card'
import { DeviceLogModel } from '../services/database/models/DeviceLog.Model'
const pkg = require('../../package.json')
export function Login() {
    const [credentials, setCredentials] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const [disableSubmit, setDisableSubmit] = useState()
    const [qrCode, setQrCode] = useState()
    const [qrCodeToken, setQrCodeToken] = useState()
    const [user, setUser] = useState()
    const navigate = useNavigate()
    function onChange(context, evt) {
        setDisableSubmit(false)
        const text = evt.target.value
        setCredentials({
            ...credentials,
            [context]: text
        })
    }
    /**
     * @description Check for current user available
     * @returns 
     */
    async function checkCurrentUser() {
        const currUser = await UserModel.getAuthenticationToken()
        setLoading(false)
        if (currUser) return navigate('/pin')
    }
    /**
     * @description For Logging in
     * @returns 
     */
    async function login() {
        try {
            setLoading(true)
            // Check if the login credentials is correct
            const loginCredentials = await axios({
                method: 'POST',
                url: `${Server.API_ENDPOINT}/user/login`,
                data: {
                    data: {
                        identifier: credentials.email,
                        password: credentials.password
                    }
                }
            })
            const dataSnapshot = loginCredentials.data.data.attributes
            
            if (!dataSnapshot.roles.includes("developer") && !dataSnapshot.roles.includes("superadmin") && !dataSnapshot.roles.includes("management")) {
                setLoading(false)
                return alert("User is not allowed to access the resource")
            }
            // Check if the current location is authorize
            const checkLocationIfAuthorize = await axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/user/me/security-log`,
                headers: {
                    ...BILIS_headers,
                    'Authorization': `Bearer ${dataSnapshot.authenticationToken}`
                }
            })
            const logHistory = checkLocationIfAuthorize.data.data.attributes

            localStorage.setItem('authToken', dataSnapshot.authenticationToken)
            await DeviceLogModel.createDeviceLog()
            if (logHistory.isAuthorized) return navigate('/pin')

            return navigate('/authorize-email')
        } catch (error) {
            setLoading(false)
            setDisableSubmit(true)
            const err = error.response?.data.error
            if (err?.name === 'INVALID_CREDENTIALS')
                return setError('Invalid Credentials')
        }
    }
    /**
     * Initialize all components
     */
    async function Init() {
        await checkCurrentUser()
    }
    useEffect(() => {
        Init()
    }, [])
    useEffect(() => { }, [loading, error, disableSubmit])
    //#region Components
    const ButtonComponent = () => {
        
        return credentials && credentials.email && credentials.password && !disableSubmit
            ? <ButtonDefault value={'Submit'} onClick={login}></ButtonDefault>
            : <ButtonDisabled value={'Submit'}/>
    }
    //#endregion
    return (
        <div>
            <div style={styles.containerFlex}>
                <div style={styles.containerContent}>
                    <div style={styles.bannerContent}>
                        <img src={IconAsset.BILISLogo} style={{ width: '200px' }} />
                    </div>
                    <FlexRowContainer>
                        {
                            !user &&
                            <>
                                <FlexColumnContainer>
                                    <h2>Login</h2>
                                    <InputPrimary type="email" placeholder="Email" onChange={(evt) => onChange('email', evt)} />
                                    <VerticalSpacing />
                                    <InputPrimary type="password" placeholder="Password" onChange={(evt) => onChange('password', evt)} />
                                    <VerticalSpacing />
                                    {
                                        loading
                                            ?
                                            <FlexColumnContainer style={{ alignItems: 'center' }}>
                                                <LoadingComponent />
                                            </FlexColumnContainer>
                                            : <ButtonComponent />
                                    }
                                </FlexColumnContainer>
                            </>
                        }
                        {
                            user &&
                            <CardPrimary style={{padding: '30px'}}>
                                <FlexColumnContainer style={{ alignContent: 'center' }}>
                                    <FlexColumnContainer style={{ alignItems: 'center' }}>
                                        <img src={user.data.attributes.photo} style={{ borderRadius: '100%', maxWidth: '130px' }} />
                                        <VerticalSpacing />
                                        <h3>{user.data.attributes.email}</h3>
                                    </FlexColumnContainer>
                                    <VerticalSpacing />
                                    <ButtonDefault value={'Log me in'} onClick={() => navigate('/dashboard/content/users')}></ButtonDefault>
                                </FlexColumnContainer>
                            </CardPrimary>
                        }
                    </FlexRowContainer>
                    {
                        error && <TypographyError>{error}</TypographyError>
                    }
                    <VerticalSpacing style={{height: '30px'}} />
                    <TypographyGray>Version {pkg.version}</TypographyGray>
                </div>
            </div>
        </div>
    )
}

const styles = {
    containerFlex: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        alignContent: 'center',
        padding: '30px',
        marginTop: '100px'
    },
    containerContent: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        width: '50vw',
        flexDirection: 'column',
        padding: '25px',
    },
    bannerContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loading: {
        width: '40px',
        height: '38px'
    },
    loadingWrapper: {
        display: 'flex',
        justifyContent: 'center'
    }
}