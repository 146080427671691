export function CompareArray( toCompare, fromCompare) {
    if (typeof toCompare === 'string') {
        toCompare = [toCompare]
    }
    if (typeof fromCompare === 'string') {
        fromCompare = [fromCompare]
    }

    return new Promise((resolve) => {
        let similaritiesMemo = {
            similarValues: [],
            hasSimilarities: false,
            nonMatchingValues: [],
        }
        toCompare.forEach((toCompareValue) => {
            fromCompare.forEach((fromCompareValue) => {
                if (toCompareValue === fromCompareValue) {
                    similaritiesMemo.similarValues.push(fromCompareValue)
                    similaritiesMemo.hasSimilarities = true
                }
            })
            if (!similaritiesMemo.similarValues.includes(toCompareValue)) {
                similaritiesMemo.nonMatchingValues.push(toCompareValue)
            }
        })

        resolve(similaritiesMemo)
    })
}
