import axios from "axios"
import { Server } from "../../../values/Server"
import { UserModel } from "./User.Model"


class BarangayRegistration {
    async getActiveRegistrations(pagination) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/barangay-registrations?pagination[page]=${pagination.page}&pagination[limit]=${pagination.limit}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
    async getBarangayRegistrationById(id) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/barangay-registration/${id}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
    async approveBarangayRegistration(id) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${Server.API_ENDPOINT}/barangay-registration/${id}/approve`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
    async declineBarangayRegistration(id) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${Server.API_ENDPOINT}/barangay-registration/${id}/decline`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
}

export const BarangayRegistrationModel = new BarangayRegistration()