import axios from "axios"
import { Server } from "../../../values/Server"
import { UserModel } from "./User.Model"

class UserProfileFeed {
    async addUserProfileFeed({ label, value, type }) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'POST',
                url: `${Server.API_ENDPOINT}/user-profile-feed`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
                data: {
                    data: {
                        label,
                        value,
                        type
                    }
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(async error => {
                const response = error.response.data
                // Refresh
                if (response?.error?.name == 'TokenExpiredError')
                    await axios({
                        method: 'POST',
                        url: `${Server.API_ENDPOINT}/user/me/refresh`,
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    }).then(snapshot => {
                        localStorage.setItem('accessToken', snapshot.data.data.attributes.accessToken)
                        resolve(this.addUserProfileFeed({ label, value, type}))
                    }).catch(error => {
                        const response = error.response.data
                        if (response?.error?.name == 'ACCESS_TOKEN_INVALID')
                            reject('ACCESS_TOKEN_INVALID')
                    })
            })
        })
    }
    async getUserProfileFeeds(page, limit, additionalQuery = '') {
        const accessToken = await UserModel.getAccessToken()
        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/user-profile-feeds?pagination[page]=${page}&pagination[limit]=${limit}${additionalQuery}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(async error => {
                const response = error.response.data
                // Refresh
                if (response?.error?.name == 'TokenExpiredError')
                    await axios({
                        method: 'POST',
                        url: `${Server.API_ENDPOINT}/user/me/refresh`,
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    }).then(snapshot => {
                        localStorage.setItem('accessToken', snapshot.data.data.attributes.accessToken)
                        resolve(this.getUserProfileFeeds(page, limit, additionalQuery))
                    }).catch(error => {
                        const response = error.response.data
                        if (response?.error?.name == 'ACCESS_TOKEN_INVALID')
                            reject('ACCESS_TOKEN_INVALID')
                    })
            })
        })
    }
    async removeUserProfileFeed(id) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'DELETE',
                url: `${Server.API_ENDPOINT}/user-profile-feed/${id}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(async error => {
                const response = error.response.data
                // Refresh
                if (response?.error?.name == 'TokenExpiredError')
                    await axios({
                        method: 'POST',
                        url: `${Server.API_ENDPOINT}/user/me/refresh`,
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    }).then(snapshot => {
                        localStorage.setItem('accessToken', snapshot.data.data.attributes.accessToken)
                        resolve(this.removeUserProfileFeed(id))
                    }).catch(error => {
                        const response = error.response.data
                        if (response?.error?.name == 'ACCESS_TOKEN_INVALID')
                            reject('ACCESS_TOKEN_INVALID')
                    })
            })
        })
    }
}

export const UserProfileFeedModel = new UserProfileFeed()