import React from 'react';
import { Button, ClickAwayListener, IconButton, Menu, MenuItem } from '@mui/material';
import { IconImage } from './Miscs';
import { IconAsset } from '../assets/IconAsset';

const IconMenu = ({ icon, items }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (index, data, onClick) => {
        onClick && onClick(index, data)
        setAnchorEl(null);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };
    
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <IconButton onClick={handleClick}>
                    <IconImage src={icon}></IconImage>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {
                        items && items.map((data, index) => <MenuItem key={index} onClick={() => handleClose(index, data, data.onClick)}>{data.name}</MenuItem>)
                    }
                </Menu>
            </div>
        </ClickAwayListener>
    );
};

export default IconMenu;
