import React, { useEffect, useState } from "react"
import { IconAsset } from "../../assets/IconAsset"
import { FlexColumnContainer, FlexRowContainer } from "../../components/Container"
import { HorizontalSpacing, LoadingComponent, VerticalSpacing } from "../../components/Miscs"
import { FeedbackModel } from "../../services/database/models/feedback/Feedback.Model"
import { TypographyHeader2, TypographyHeader3, TypographyDefault, TypographyGray } from "../../components/Typography"
import { CardPrimary, CardSecondary } from "../../components/Card"
import { BILIS_colors } from "../../values/String"
import { Pagination } from "@mui/material"
export function Feedback() {
    const [feedbacks, setFeedbacks] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [selectedFeedback, setSelectedFeedback] = useState(null)
    const [selectedFeedbackId, setSelectedFeedbackId] = useState(null)
    const [selectedPage, setSelectedPage] = useState(1)
    const [selectedFeedbackLoading, setSelectedFeedbackLoading] = useState(false)
    async function Init() {
        await getFeedbacks(selectedPage)
        setLoading(false)
    }
    // API
    async function getFeedbacks(page) {
        const feedbacks = await FeedbackModel.getFeedbacks({
            page: page,
            limit: 8
        })
        setFeedbacks(feedbacks)
        setLoading(false)
    }
    // On Click a Feedback
    async function onFeedbackClick(id, index) {
        setSelectedFeedbackLoading(true)
        setSelectedFeedbackId(id)
        const feedback = await FeedbackModel.getFeedbackById(id)
        setSelectedFeedback(feedback)
        setSelectedFeedbackLoading(false)
    }
    //#region Components
    // The lists of the feedback
    const FeedbackList = () => {
        return (
            <FlexColumnContainer style={{height: '80vh' }}>
                {
                    feedbacks.data.map((item, index) => {
                        console.log('LOAD')
                        if (selectedFeedbackId !== item.id) {
                            return (
                                <FlexRowContainer 
                                        key={index} 
                                        style={{ 
                                            alignItems: 'center',
                                            width: '600px',
                                            borderRadius: '10px',
                                            cursor: 'pointer'
                                        }} 
                                        onClick={() => onFeedbackClick(item.id, index)}>
                                        <CardPrimary
                                            style={{
                                                width: '100%',
                                            }}
                                            transition={{
                                                duration: 0.5
                                            }}
                                            whileHover={{
                                                backgroundColor: BILIS_colors.secondary,
                                                color: '#FFFFFF'
                                            }}>
                                            <FlexRowContainer>
                                                <FlexRowContainer style={{ alignItems: 'center', margin: '10px' }}>
                                                    <FlexRowContainer style={{ width: '300px' }}>
                                                        <h3>{item.attributes.title}</h3>
                                                    </FlexRowContainer>
                                                    <HorizontalSpacing />
                                                    <p>{new Date(item.attributes.createdAt).toDateString()}</p>
                                                </FlexRowContainer>
                                            </FlexRowContainer>
                                        </CardPrimary>
                                </FlexRowContainer>
                            )
                        }

                        if (selectedFeedbackId === item.id) {
                            return (
                                <FlexRowContainer 
                                    key={index} 
                                    style={{ 
                                        alignItems: 'center',
                                        width: '600px',
                                        borderRadius: '10px',
                                        cursor: 'default'
                                    }} 
                                    >
                                        <CardPrimary style={{width: '100%', backgroundColor: BILIS_colors.primary }}>
                                            <FlexRowContainer>
                                                <FlexRowContainer style={{ alignItems: 'center', margin: '10px'  }}>
                                                    <FlexRowContainer style={{ width: '300px' }}>
                                                        <h3 style={{ color: 'white' }}>{item.attributes.title}</h3>
                                                    </FlexRowContainer>
                                                    <HorizontalSpacing />
                                                    <p style={{ color: 'white' }}>{new Date(item.attributes.createdAt).toDateString()}</p>
                                                </FlexRowContainer>
                                            </FlexRowContainer>
                                        </CardPrimary>
                                </FlexRowContainer>
                            )
                        }
                    })
                }
            </FlexColumnContainer>
        )
    }
    // The content of the feedback
    const FeedbackView = () => {
        if (!selectedFeedback)
            return (
                <FlexColumnContainer>
                </FlexColumnContainer>
            )
        const user = selectedFeedback?.included?.find(item => item.type === 'user')
        const attachments = selectedFeedback.data.attributes.attachments
        const date = `${new Date(selectedFeedback.data.attributes.createdAt).toDateString()} ${new Date(selectedFeedback.data.attributes.createdAt).toLocaleTimeString()}`
        return (
            <>
            {
                !selectedFeedbackLoading 
                    ? <FlexColumnContainer style={{ padding: '20px', width: '600px', overflowY: 'scroll', height: '650px'}}>
                        <TypographyGray>
                            {date}
                        </TypographyGray>
                        <h2>{selectedFeedback.data.attributes.title}</h2>
                        <VerticalSpacing />
                        <FlexRowContainer style={{alignItems: 'center', padding: '10px'}}>
                            <TypographyDefault>By: {user?.attributes?.email || 'Anonymous'}</TypographyDefault>
                        </FlexRowContainer>
                        <CardPrimary>
                            <FlexRowContainer style={{alignItems: 'center'}}>
                                {
                                    selectedFeedback.data.attributes.type == 'bugReport'
                                        ? <IconAsset.Bug />
                                        : <IconAsset.Inbox />
                                }
                                {
                                    selectedFeedback.data.attributes.type == 'bugReport'
                                        ? <TypographyDefault>Bug Report</TypographyDefault>
                                        : <TypographyDefault>Suggestions</TypographyDefault>
                                }
                            </FlexRowContainer>
                        </CardPrimary>
                        <FlexColumnContainer style={{padding: '10px'}}>
                            <TypographyDefault>{selectedFeedback?.data?.attributes.body}</TypographyDefault>
                        </FlexColumnContainer>
                        <VerticalSpacing style={{ height: '30px' }} />
                        {
                            attachments.length > 0 &&
                            <FlexColumnContainer>
                                <CardSecondary>
                                <FlexColumnContainer>
                                    <TypographyHeader3 style={{padding: '10px'}}>Attachments</TypographyHeader3>
                                    <VerticalSpacing />
                                    {
                                        attachments.length > 0 && (
                                            attachments.map((item, index) => {
                                                return (
                                                    <FlexColumnContainer>
                                                        <img key={index} src={item} style={{ maxWidth: '100%' }} />
                                                    </FlexColumnContainer>
                                                )
                                            })

                                        )
                                    }
                                </FlexColumnContainer>
                                </CardSecondary>
                            </FlexColumnContainer>
                        }
                    </FlexColumnContainer>
                    : <LoadingComponent />
            }
            </>
        )
    }
    //#endregion
    useEffect(() => { }, [selectedFeedback])
    useEffect(() => {
        Init()
    }, [])
    return (
        <div>
            <TypographyHeader2>
                User Feedbacks
            </TypographyHeader2>
            {
                isLoading
                    ? <LoadingComponent />
                    :
                    <FlexRowContainer>
                        <FlexColumnContainer style={{alignItems: 'center'}}>
                            <FeedbackList />
                            <Pagination 
                                count={feedbacks.meta.pagination.totalPages}
                                onChange={(page, index) => {
                                    getFeedbacks(index)
                                }}
                                hidePrevButton={feedbacks.links.prev ? false : true}
                            >
                            </Pagination>
                        </FlexColumnContainer>
                        <FeedbackView />
                    </FlexRowContainer>
            }
        </div>
    )
}
