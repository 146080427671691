import axios from "axios"
import { Server } from "../../../values/Server"
import { UserModel } from "./User.Model"

class Analytic {
    /**
     * @description Get user basic analytics
     * @returns 
     */
    async getUserAnalytics() {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/analytics/users`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
            .then(snapshot => resolve(snapshot.data.data))
            .catch(error => reject(error.response.data))
        })
    }
    /**
     * @description Get user's registration analytics
     * @returns 
     */
    async getUserRegistrationAnalytics({ page, limit }) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/analytics/user-registrations?pagination[page]=${page}&pagination[limit]=${limit}&sort[date]=-1`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => resolve(snapshot.data.data))
            .catch(error => reject(error.response.data))
        })
    }

    async getFormAnalytics(type, { page, limit }) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/analytics/form/${type}?pagination[page]=${page}&pagination[limit]=${limit}&sort[date]=-1`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => resolve(snapshot.data.data))
            .catch(error => reject(error.response.data))
        })
    }
}

export const AnalyticModel = new Analytic()