import axios from "axios"
import { Server } from "../../values/Server"
import { UserModel } from "./models/User.Model"

class ServerBuild {
    getAllServerBuilds(pagination) {
        return new Promise(async (resolve, reject) => {
            const accessToken = await UserModel.getAccessToken()
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/service/server/builds?pagination[page]=${pagination.page}&pagination[limit]=${pagination.limit}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
}

export const ServerBuildModel = new ServerBuild()