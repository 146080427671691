import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import { IconAsset } from "../assets/IconAsset"
import { BILIS_colors } from "../values/String"

export const Miscs = {
    spacing: {
        height: '10px',
        width: '10px'
    },
    clickableIcon: {
        width: '20px',
        userSelect: 'none',
        cursor: 'pointer',
    },
    icon: {
        width: '30px',
        height: '30px',
        userSelect: 'none',
        margin: '0 10px'
    },
    loadingIcon: {
        width: '38px',
        height: '38px',
        userSelect: 'none',
        margin: '0 10px'
    }
}

export function HorizontalSpacing({ style }) {
    return (
        <div style={{ width: '10px', ...style }}></div>
    )
}
export function VerticalSpacing({ style }) {
    return (
        <div style={{ height: '10px', ...style }}></div>
    )
}
export function IconImage({ src, style, onClick }) {
    return (
        <img
            src={src}
            style={{
                margin: '15px',
                width: '20px',
                userSelect: 'none',
                cursor: onClick ? 'pointer' : 'default',
                ...style
            }}
            onClick={onClick}
        />
    )
}
export function IconRoundLargeImage({ src, style, imageStyle, onClick }) {
    return (
        <div 
            style={{
                display: 'flex',
                width: '70px',
                height: '70px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '70px',
                padding: '10px',
                ...style
            }}>
            <img
                src={src}
                style={{
                    width: '60px',
                    userSelect: 'none',
                    cursor: onClick ? 'pointer' : 'default',
                    ...imageStyle
                }}
                onClick={onClick}
            />
        </div>
    )
}

export function ToggleSwitch({defaultState, onClick }) {
    const [isEnabled, setEnabled] = useState(defaultState)

    function toggle() {
        onClick(isEnabled)
        setEnabled(!isEnabled)
    }

    useEffect(() => {}, [isEnabled])
    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 30
    };
      
    const styles = {
        switch: {
            on: {
                width: '50px',
                height: '25px',
                display: 'flex',
                backgroundColor: BILIS_colors.primary,
                justifyContent: 'flex-end',
                borderRadius: '50px',
                padding: '7px',
                cursor: 'pointer'
            },
            off: {
                width: '50px',
                height: '25px',
                display: 'flex',
                backgroundColor: 'lightgray',
                justifyContent: 'flex-start',
                borderRadius: '50px',
                padding: '7px',
                cursor: 'pointer'
    
            }
        },
        handle: {
            width: '25px',
            height: '25px',
            backgroundColor: 'white',
            borderRadius: '40px'
        }
    }
    return (
        <div style={isEnabled ? styles.switch.on : styles.switch.off} onClick={() => toggle()}>
            <motion.div style={styles.handle} layout transition={spring} />
        </div>
    )
}

export function LoadingComponent() {
    return (
        <div>
            <img src={IconAsset.Loading} style={Miscs.loadingIcon} />
        </div>
    )
}