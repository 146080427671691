import * as React from 'react';
import { useState, useEffect } from 'react';
import { FlexColumnContainer, FlexRowContainer } from './Container';
import { Button, Chip, FormControl, Grid, InputLabel, MenuItem, Popover, Select, Typography } from '@mui/material';
import { IconAsset } from '../assets/IconAsset';
import { IconImage, LoadingComponent, Miscs } from './Miscs';
import FormDialog from './FormDialog';
import { InputPrimary } from './Inputs';
import { Table } from './Table';
import { CapitalizeFirstLetter } from '../utils/StringHelper';
import { TypographyDefault } from './Typography';
import { BILIS_colors } from '../values/String';




export default function DataGrid({ maxHeight = '70vh', headers, items, filterOptions, onAddSubmit, onFilterSubmit, onFilterClear, onItemRemoved, onItemClick, onClickRefresh, defaultFilterContent, isLoading }) {

  const [isShowFilter, setShowFilter] = useState(false)
  const [isShowAdd, setShowAdd] = useState(false)
  const [filterContent, setFilterContent] = useState()
  const [_defaultFilterContent, setDefaultFilterContent] = useState(defaultFilterContent)
  const [isDeleteMode, setDeleteMode] = useState(false)
  const [isRefreshing, setRefreshing] = useState(false)
  let addContent = {}

  const AddFormDialog = () => {
    return isShowAdd &&
      <FormDialog
        title="Add New"
        onSubmit={async (willClose) => {
          onAddSubmit && onAddSubmit(addContent)
          willClose()
        }}
        onClose={() => {
          setShowAdd(false)
        }}>
        <FlexColumnContainer style={{ padding: 20 }}>
          {
            filterOptions.attributes.map((attr, index) => {
              return (
                <Grid key={index} container direction={"row"} style={{ padding: 10, alignItems: "center" }}>
                  <Grid item xs={3.3}>
                    <Typography style={{ fontWeight: "bold" }}>{CapitalizeFirstLetter(attr)}:</Typography>
                  </Grid>
                  <Grid item xs={3.3}>
                    <InputPrimary style={{ width: 120 }} placeholder={'Value'} onChange={(evt) => {
                      addContent = {
                        ...addContent,
                        [attr]: evt.target.value
                      }
                    }}></InputPrimary>
                  </Grid>
                </Grid>
              )
            })
          }
        </FlexColumnContainer>
      </FormDialog>
  }
  return (
    <FlexColumnContainer style={{ width: '100%' }}>
      <AddFormDialog />
      <FlexRowContainer>
        <FlexRowContainer style={{ cursor: "pointer", alignItems: "center" }} onClick={() => {
          setShowFilter(true)
        }}>
          <IconImage src={IconAsset.Filter}></IconImage>
          <Typography style={{ color: "#428FDB" }}>Filter</Typography>
        </FlexRowContainer>
        {
          onAddSubmit &&
          <FlexRowContainer style={{ cursor: "pointer", alignItems: "center" }} onClick={() => {
            setShowAdd(!isShowAdd)
          }}>
            <IconImage src={IconAsset.PlusBlue}></IconImage>
            <Typography style={{ color: "#428FDB" }}>Add</Typography>
          </FlexRowContainer>
        }
        {
          onItemRemoved &&
          <FlexRowContainer style={{ cursor: "pointer", alignItems: "center" }} onClick={() => {
            setDeleteMode(!isDeleteMode)
          }}>
            <IconImage src={IconAsset.TrashCanBlue}></IconImage>
            <Typography style={{ color: "#428FDB" }}>Delete Mode</Typography>
          </FlexRowContainer>
        }
        {
          onClickRefresh &&
          <FlexRowContainer style={{ cursor: "pointer", alignItems: "center" }} onClick={async () => {
            setRefreshing(true)
            await onClickRefresh()
            setRefreshing(false)
          }}>
            <IconImage src={IconAsset.RefreshBlue}></IconImage>
            <Typography style={{ color: "#428FDB" }}>Refresh</Typography>
          </FlexRowContainer>
        }
      </FlexRowContainer>
      <FlexRowContainer style={{ padding: 10 }}>

        {
          filterContent &&
          <Chip label={`${filterContent.attribute} ${filterContent.query} ${filterContent.value}`} variant="outlined" onDelete={() => {
            setFilterContent()
            onFilterClear()
          }} />
        }
        {
          _defaultFilterContent &&
          <Chip label={`${_defaultFilterContent.attribute} ${_defaultFilterContent.query} ${_defaultFilterContent.value}`} variant="outlined" onDelete={() => {
            setDefaultFilterContent(null)
            setFilterContent()
            onFilterClear()
          }} />
        }
      </FlexRowContainer>
      {
        isRefreshing &&
        <FlexColumnContainer style={{ margin: '20px' }}>
          <LoadingComponent />
        </FlexColumnContainer>
      }
      <FlexColumnContainer>
        <FlexColumnContainer style={{ maxHeight: maxHeight, overflowY: 'scroll' }}>
          <table style={{ ...Table.default }}>
            <thead>
              <tr>
                {
                  onItemClick && <th key={'A'} style={Table.identifier}></th>
                }
                {
                  headers
                  && headers.map((header, index) => {
                    return <th key={index} style={Table.identifier}>{header}</th>
                  })
                }
              </tr>
            </thead>

            {
              items && items.length > 0 && items.map((item, index) => {
                return (
                  <tr key={index}>
                    {
                      onItemClick &&
                      <th style={Table.cells}>
                        <div style={{ cursor: "pointer" }} onClick={() => { onItemClick(item, index) }}>
                          <TypographyDefault style={{ color: BILIS_colors.secondary }}>View</TypographyDefault>
                        </div>
                      </th>
                    }
                    {
                      item && Object.entries(item).map((childItem, childIndex) => {
                        if (headers?.includes(childItem[0])) {
                          return <th key={childIndex[1]} style={Table.cells}>{childItem[1]}</th>
                        }
                      })
                    }
                    {
                      isDeleteMode &&
                      <th style={Table.cells}>
                        <img src={IconAsset.Trashcan} style={Miscs.clickableIcon} draggable={false} onClick={() => {
                          onItemRemoved(item.id, index, item)
                        }} />
                      </th>

                    }
                  </tr>
                )
              })
            }


          </table>
          {
            isLoading &&
            <FlexColumnContainer style={{ alignItems: 'center', justifyItems: 'center', margin: '50px' }}>
              <LoadingComponent />
            </FlexColumnContainer>
          }
          {
            items && items.length == 0 &&
            <FlexColumnContainer style={{ alignItems: 'center', justifyItems: 'center', margin: '50px' }}>
              <TypographyDefault style={{ fontWeight: 'bold' }}>Ummm... looks empty.</TypographyDefault>
            </FlexColumnContainer>
          }
        </FlexColumnContainer>
        {
          isShowFilter &&
          <FilterFormDialog
            filterOptions={filterOptions}
            onSubmit={(content) => {
              onFilterSubmit && onFilterSubmit(content)
              setFilterContent(content)
            }}
            onClose={() => {
              setShowFilter(false)
            }}
          />
        }
      </FlexColumnContainer>
    </FlexColumnContainer>
  );
}

const FilterFormDialog = ({ filterOptions, onSubmit, onClose }) => {
  const query = [
    'equals'
  ]
  const [selectedFilterAttribute, setSelectedFilterAttribute] = useState()
  const [selectedFilterQuery, setSelectedFilterQuery] = useState(query[0])
  const [selectedFilterValue, setSelectedFilterValue] = useState()

  return (
    <FormDialog
      title="Filter"
      onSubmit={async (willClose) => {
        onSubmit && onSubmit({
          attribute: selectedFilterAttribute,
          query: selectedFilterQuery,
          value: selectedFilterValue
        })
        willClose()
      }}
      onClose={onClose}>
      <FlexRowContainer style={{ padding: 20 }}>
        <FormControl style={{ width: 120 }}>
          <InputLabel id="attribute-select-label">Attribute</InputLabel>
          <Select
            labelId="attribute-select-label"
            id="attribute-select"
            label="attribute"
            placeholder="attribute"
            value={selectedFilterAttribute || ''}
            onChange={(evt) => {
              setSelectedFilterAttribute(evt.target.value)
            }}
          >
            {
              filterOptions?.attributes && filterOptions.attributes.map((data, index) => {
                return <MenuItem key={index} value={data}>{data}</MenuItem>
              })
            }
          </Select>
        </FormControl>
        <FormControl style={{ width: 120 }}>
          <InputLabel id="query-select-label">Query</InputLabel>
          <Select
            labelId="query-select-label"
            id="query-select"
            label="query"
            placeholder="query"
            value={selectedFilterQuery || query[0]}
            onChange={(evt) => {
              setSelectedFilterQuery(evt.target.value)
            }}
          >
            {
              query && query.map((data, index) => {
                return <MenuItem key={index} value={data} >{data}</MenuItem>
              })
            }
          </Select>
        </FormControl>
        <InputPrimary style={{ width: 120 }} onChange={(evt) => {
          setSelectedFilterValue(evt.target.value)
        }}>
        </InputPrimary>
      </FlexRowContainer>
    </FormDialog>
  )
}