import { FlexColumnContainer, FlexRowContainer } from "../../../components/Container";
import { useEffect, useState } from "react";
import { BadgeModel } from "../../../services/database/models/Badge.Model";
import { Table } from "../../../components/Table"
import { IconAsset } from "../../../assets/IconAsset";
import { LoadingComponent, Miscs } from "../../../components/Miscs";
import { SVGReader } from "../../../components/SVGReader";


export function BadgeLists() {
    const [badges, setBadges] = useState()
    const [isLoading, setLoading] = useState(true)

    async function getAllBadges() {
        const badges = await BadgeModel.getAllBadges(1, 10)
        setBadges(badges)
        setLoading(false)
    }

    useEffect(() => {
        getAllBadges()
    }, [])

    return (
        <FlexColumnContainer>
            {
                !isLoading
                ?
                <FlexRowContainer style={{ overflowY: 'scroll', height: '65vh' }}>
                    <table style={Table.default}>
                        <tr>
                            <th style={Table.identifier}></th>
                            <th style={Table.identifier}>Photo</th>
                            <th style={Table.identifier}>Code</th>
                            <th style={Table.identifier}>Name</th>
                        </tr>
                        {
                            badges && badges.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <th style={Table.cells}>
                                            <img src={IconAsset.Trashcan} style={Miscs.clickableIcon} draggable={false} />
                                        </th>
                                        <th style={Table.cells}>
                                            <img src={item.attributes.photo} style={{width: '120px'}}/>
                                        </th>
                                        <th style={Table.cells}>{item.attributes.code}</th>
                                        <th style={Table.cells}>
                                            {item.attributes.name}
                                        </th>
                                    </tr>
                                )
                            })
                        }
                    </table>
                </FlexRowContainer>
                : <LoadingComponent></LoadingComponent>
            }
        </FlexColumnContainer>
    )
}