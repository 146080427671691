import axios from "axios"
import { Server } from "../../../../values/Server"
import { UserModel } from "../User.Model"

class IssueCategory {
    /**
     * @description Fetch User Feedbacks
     * @param {*} param0 
     * @returns 
     */
    getIssueCategories({ page, limit, query }) {
        return new Promise(async (resolve, reject) => {
            const accessToken = await UserModel.getAccessToken()
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/issue-categories?pagination[page]=${page}&pagination[limit]=${limit}&${query}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => resolve(snapshot.data.data))
                .catch(error => reject(error))
        })
    }
    /**
    * @description Add User Issue Category
    * @param {*} param0 
    * @returns 
    */
    addIssueCategory(label, value) {
        return new Promise(async (resolve, reject) => {
            const accessToken = await UserModel.getAccessToken()
            axios({
                method: 'POST',
                url: `${Server.API_ENDPOINT}/issue-category`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
                data: {
                    data: {
                        label: label,
                        value: value
                    }
                }
            }).then(snapshot => resolve(snapshot.data.data))
                .catch(error => reject(error))
        })
    }
    /**
    * @description Remove User Issue Category
    * @param {*} param0 
    * @returns 
    */
    removeIssueCategory(id) {
        return new Promise(async (resolve, reject) => {
            const accessToken = await UserModel.getAccessToken()
            axios({
                method: 'DELETE',
                url: `${Server.API_ENDPOINT}/issue-category/${id}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => resolve(snapshot.data.data))
                .catch(error => reject(error))
        })
    }
}

export const IssueCategoryModel = new IssueCategory()