import {io} from 'socket.io-client';
import { Server } from '../../values/Server';

const server = io(Server.API_ORIGIN, {path: '/service/socket'});
/**
 * Connect to a websocket
 * @returns
 */
export const socketIO = () => {
  return server;
};

export const revokeURL = url => {
  return server.off(url);
};