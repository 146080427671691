import axios from "axios"
import { Server } from "../../../values/Server"
import { UserModel } from "./User.Model"

class Barangay {
    async getRegisteredBarangays({ pagination }) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/barangays?pagination[page]=${pagination.page}&pagination[limit]=${pagination.limit}&include=[region,municipal]`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }

    async registerBarangay(psgcId, emails) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${Server.API_ENDPOINT}/barangay/${psgcId}`,
                data: {
                    data: {
                        assignAdmins: emails
                    }
                },
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
    async isBarangayRegistered(id) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/barangay/${id}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }

    async getBarangay(id) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/barangay/${id}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }

    async getBarangayByName(name) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/barangays?pagination[page]=1&pagination[limit]=10&include=[region,municipal]&filter[name][$regex]=${name}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
}

export const BarangayModel = new Barangay()