import { useEffect, useState } from "react";
import { TypographyHeader2, TypographyDefault, TypographyGray } from "../../components/Typography";
import { UserModel } from "../../services/database/models/User.Model";
import { IconImage, IconRoundLargeImage, LoadingComponent } from "../../components/Miscs";
import { FlexColumnContainer, FlexRowContainer } from "../../components/Container";
import { ButtonDefault } from "../../components/Buttons";
import { useNavigate } from "react-router";
import { DeviceLogModel } from "../../services/database/models/DeviceLog.Model";
import { IconAsset } from "../../assets/IconAsset";
import { BILIS_colors } from "../../values/String";


export function Profile() {
    const [currentUser, setCurrentUser] = useState()
    const [deviceLogs, setDeviceLogs] = useState()
    const [isLoading, setLoading] = useState(true)
    const navigate = useNavigate()
    async function init() {
        const user = await UserModel.getCurrentUser()
        setCurrentUser(user.data.data)
        setLoading(false)


        const getDeviceLogs = await DeviceLogModel.getCurrentUsersDeviceLogs({
            page: 1,
            limit: 10
        })

        setDeviceLogs(getDeviceLogs.data)
    }


    useEffect(() => {
        init()
    }, [])

    return (
        isLoading
            ? <LoadingComponent />
            :
            <>
                <TypographyHeader2>
                    Profile
                </TypographyHeader2>
                <FlexRowContainer>
                    <FlexColumnContainer style={{padding: 20}}>
                        <IconRoundLargeImage src={currentUser.attributes.photo} style={{border: '1px solid gray', width: '100px', height: '100px'}}></IconRoundLargeImage>
                        <div style={{height: '30px'}}></div>
                        <TypographyDefault style={{fontWeight: 'bold'}}>Email</TypographyDefault>
                        <TypographyGray>{currentUser.attributes.email}</TypographyGray>
                        <TypographyDefault style={{fontWeight: 'bold'}}>Roles</TypographyDefault>
                        {
                            currentUser.attributes.roles && 
                                currentUser.attributes.roles.map((item) => {
                                    return <TypographyGray>{item}</TypographyGray>
                                })
                        }
                        <div style={{height: '30px'}}></div>
                        <FlexRowContainer>
                            <ButtonDefault value={'Signout'} onClick={() => navigate('/login')}></ButtonDefault>
                        </FlexRowContainer>
                    </FlexColumnContainer>
                    <FlexColumnContainer style={{width: '50%', }}>
                        <TypographyHeader2>Device Logs</TypographyHeader2>
                        <FlexColumnContainer style={{height: '70vh', overflowY: "scroll"}}>
                            {
                                deviceLogs &&
                                    deviceLogs.map( (item, index) => {
                                  
                                        return (
                                            <>
                                                <FlexColumnContainer style={{background: 'whitesmoke', padding: '10px', borderRadius: 10,margin: 10}}>
                                                    <FlexRowContainer style={{alignItems: "center"}}>
                                                        <IconImage src={IconAsset.BrowserPrimary} style={{width: '25px', margin: 5}}></IconImage>
                                                        <TypographyDefault style={{fontWeight: 'bold'}}>{item.attributes.browser}{item.attributes.baseOS ? ' | ' + item.attributes.baseOS + ' OS ' : ' | Unknown Device'}</TypographyDefault>
                                                    </FlexRowContainer>
                                                    <FlexRowContainer style={{alignItems: "center"}}>
                                                        <IconImage src={IconAsset.VersionPrimary} style={{width: '25px', margin: 5}}></IconImage>
                                                        <TypographyDefault>{item.attributes.systemVersion}</TypographyDefault>
                                                    </FlexRowContainer>
                                                    <FlexRowContainer style={{alignItems: "center"}}>
                                                        <IconImage src={IconAsset.EmailPrimary} style={{width: '25px', margin: 5}}></IconImage>
                                                        <TypographyDefault>{item.attributes.authorizationType}</TypographyDefault>
                                                    </FlexRowContainer>
                                                    <FlexRowContainer style={{alignItems: "center"}}>
                                                        <IconImage src={IconAsset.ClockPrimary} style={{width: '25px', margin: 5}}></IconImage>
                                                        <TypographyDefault>{new Date(item.attributes.lastLoggedAt).toLocaleString()}</TypographyDefault>
                                                    </FlexRowContainer>
                                                    {
                                                            DeviceLogModel.isCurrentDevice(item.attributes.browser, item.attributes.uniqueId, item.attributes.systemVersion, item.attributes.authorizationType)
                                                                ?
                                                                    <FlexRowContainer style={{alignItems: "center", width: '15vw', border: "1px solid #C10215", padding: 10, borderRadius: 5, }}>
                                                                        <TypographyDefault style={{color: BILIS_colors.primary}}>Current</TypographyDefault>
                                                                    </FlexRowContainer>
                                                                :
                                                                    <FlexRowContainer style={{alignItems: "center", cursor: "pointer", background: BILIS_colors.primary, borderRadius: 5, width: "15vw", padding: 5}} onClick={async () => {
                                                                        await DeviceLogModel.signOutDevice(item.id)
                                                                        await init()
                                                                    }}>
                                                                        <IconImage src={IconAsset.SignoutWhite} style={{width: '25px', margin: 5, cursor: "pointer"}}></IconImage>
                                                                        <TypographyDefault style={{color: "white"}}>Sign Out</TypographyDefault>
                                                                    </FlexRowContainer>
                                                    }
                                                </FlexColumnContainer>
                                            </>
                                        )
                                    })
                            }
                        </FlexColumnContainer>
                    </FlexColumnContainer>
                </FlexRowContainer>
            </>

    )
}