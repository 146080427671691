import moment from 'moment'
export async function getCurrentWeekInArray() {
    return new Promise(resolve => {
        var startOfWeek = moment().startOf('isoWeek');
        var endOfWeek = moment().endOf('isoWeek');
        var days = []
        var day = startOfWeek;
        while (day <= endOfWeek) {
            days.push(`${day.get('year')}-${day.get('month').length !== 2 ? `0${day.get('month') + 1}` : `${day.get('month') + 1}`}-${day.get('date')}`);
            day = day.clone().add(1, 'd');
        }
        resolve(days)
    })
}

export async function getPreviousDaysFromCurrent(dayCount) {
    return new Promise(resolve => {
        const dates = [];
        const today = moment();

        for (let i = 0; i < dayCount; i++) {
            const previousDate = today.clone().subtract(i, 'days');
            dates.push(previousDate.format('YYYY-MM-DD'));
        }

        resolve(dates);
    })
}