import React, { useEffect, useState } from "react"
import { CardPrimary } from "../../../components/Card"
import { ColumnChart, DonutChart } from "../../../components/Chart"
import { FlexColumnContainer, FlexRowContainer } from "../../../components/Container"
import { HorizontalSpacing, LoadingComponent, VerticalSpacing } from "../../../components/Miscs"
import { AnalyticModel } from "../../../services/database/models/Analytic.Model"
import { getCurrentWeekInArray, getPreviousDaysFromCurrent } from "../../../utils/Date"
import { Socket } from "socket.io-client"
import { socketIO } from "../../../services/socket/Socket.IO"

export function UserDashboardContent() {
    const [isLoading, setLoading] = useState(true)
    const [analytics, setAnalytics] = useState({})
    const [formAnalytics, setFormAnalytics] = useState({})

    // Initialize
    async function Init() {
        const previousDays = await getPreviousDaysFromCurrent(10)
        await previousDays.reverse()
        await getUserAnalytics(previousDays)
        await getFormAnalytics(previousDays)
        setLoading(false)
    }
    // async function InitFormRegistration() {
    //     const previousDays = await getPreviousDaysFromCurrent(10)
    //     await previousDays.reverse()
    //     setLoading(false)
    // }
    // Get Analytics for User
    async function getUserAnalytics(range) {
        const userAnalytics = await AnalyticModel.getUserAnalytics()
        let donutAnalyticsUser = {
            totalBasicUsers: userAnalytics.totalBasicUsers,
            totalFullyVerifiedUsers: userAnalytics.totalFullyVerifiedUsers,
        }
        const userRegistrationAnalytics = await AnalyticModel.getUserRegistrationAnalytics({
            page: 1,
            limit: 10
        })

        const userRegistrationSeries = []
        // Compare
        for (const day of range) {
            let yAxis = 0
            for (const snapshot of userRegistrationAnalytics) {
                if (snapshot._id == day) {
                    yAxis = snapshot.userRegisteredCount
                }
            }
            userRegistrationSeries.push(
                {
                    x: day,
                    y: yAxis
                }
            )
        }

        const series = Object.values(donutAnalyticsUser)
        setAnalytics({
            ...analytics,
            usersByVerificationStatus: {
                labels: ['Basic Users', 'Fully Verified Users'],
                series: series
            },
            users: {
                totalUsers: userAnalytics.totalUsers,
                totalAdmins: userAnalytics.totalAdmins
            },
            userRegistration: userRegistrationSeries
        })
    }

    async function getFormAnalytics(range) {
        const formAnalytics = await AnalyticModel.getFormAnalytics('Setup Account', {
            page: 1,
            limit: 10
        })
        const setupAccountRegistrationSeries = []
        // Compare
        for (const day of range) {
            let yAxis = 0
            for (const snapshot of formAnalytics) {
                if (snapshot._id == day) {
                    yAxis = snapshot.formCount
                }
            }
            setupAccountRegistrationSeries.push(
                {
                    x: day,
                    y: yAxis
                }
            )
        }

        setFormAnalytics({
            setupAccountRegistration: setupAccountRegistrationSeries
        })

    }

    async function openSockets() {
        socketIO().on(`/analytics/user-registrations`, async () => {
            Init()
        })
        socketIO().on(`/analytics/form/Setup Account`, async () => {
            Init()
        })
    }
    useEffect(() => {
        Init()
        openSockets()
    }, [])
    return (
        <div>
            {
                isLoading
                    ? <LoadingComponent />
                    : <FlexColumnContainer style={{ overflowY: 'scroll', height: '600px' }}>
                        <FlexRowContainer>
                            <CardPrimary>
                                <FlexColumnContainer>
                                    <p>Total Users</p>
                                    <FlexRowContainer style={{ alignItems: 'center', justifyContent: 'center', width: '350px' }}>
                                        <h1>{analytics.users?.totalUsers}</h1>
                                    </FlexRowContainer>
                                </FlexColumnContainer>
                            </CardPrimary>
                            <CardPrimary style={{ width: '350px' }}>
                                <FlexColumnContainer>
                                    <p>Total Admins</p>
                                    <FlexRowContainer style={{ alignItems: 'center', justifyContent: 'center', width: '350px' }}>
                                        <h1>{analytics.users?.totalAdmins}</h1>
                                    </FlexRowContainer>
                                </FlexColumnContainer>
                            </CardPrimary>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <CardPrimary>
                                <FlexColumnContainer>
                                    <p>User Analytics</p>
                                    <DonutChart labels={analytics.usersByVerificationStatus?.labels} series={analytics.usersByVerificationStatus?.series} />
                                </FlexColumnContainer>
                            </CardPrimary>
                            <CardPrimary>
                                <FlexColumnContainer>
                                    <p>User Registered ( Weekly Scope )</p>
                                    <ColumnChart
                                        width='600px'
                                        series={[
                                            {
                                                data: analytics.userRegistration
                                            }
                                        ]} />
                                </FlexColumnContainer>
                            </CardPrimary>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <CardPrimary>
                                <FlexColumnContainer>
                                    <p>Setup Account</p>
                                    <ColumnChart
                                        width='600px'
                                        series={[
                                            {
                                                data: formAnalytics.setupAccountRegistration
                                            }
                                        ]} />
                                </FlexColumnContainer>
                            </CardPrimary>
                        </FlexRowContainer>
                        <div style={{marginTop: '200px'}}></div>
                        <VerticalSpacing style={{ height: '250px' }} />
                    </FlexColumnContainer>
            }

        </div>
    )
}

