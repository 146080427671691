import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"
import { Route, Routes, useLocation, useNavigate } from "react-router"
import { Containers, FlexColumnContainer, FlexRowContainer } from "../../components/Container"
import { BILIS_colors } from "../../values/String"
import { Inputs } from "../../components/Inputs"
import { BugReportCategories } from "./categories-pages/BugReport.Categories"
import { NavBar } from "../../components/NavigationBar"
import { TypographyHeader2 } from "../../components/Typography"
export function Categories() {
    const location = useLocation()
    const navigate = useNavigate()

    const [navBarItem, setNavBarItem] = useState([
        {
            name: 'Feedback - Bug Reports',
            urlParams: '/dashboard/categories/feedback/bug-reports',
        },
        {
            name: 'Advertisement',
            urlParams: '/dashboard/categories/advertisement',
        }
    ])
    function Init() {
        navigate('/dashboard/categories/feedback/bug-reports')
    }
    //#endregion
    useEffect(() => {
        Init()
    }, [])
    return (
        <div>
            <TypographyHeader2>
                Categories
            </TypographyHeader2>
            <FlexColumnContainer>
                <FlexRowContainer>
                    <NavBar navigationItems={navBarItem}/>
                </FlexRowContainer>
                <div style={styles.spotlight}>
                    <Routes>  
                        <Route path="/feedback/bug-reports" element={<BugReportCategories />}/>
                    </Routes> 
                </div>
            </FlexColumnContainer>
        </div>
    )
}

const styles = {
    spotlight: {
        marginTop: '15px',
        width: '100%',
        height: '80vh'
    },
}