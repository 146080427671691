import axios from "axios";
import { Server } from "../../../values/Server";
import { UserModel } from "./User.Model";

class Application {
    async updateApplication(os, versions) {
        return new Promise(async resolve => {
            const currUser = await UserModel.getCurrentUser()
            await axios({
                method: "PUT",
                url: `${Server.API_ENDPOINT}/application/${os}`,
                headers: {
                    'Authorization': `Bearer ${currUser.accessToken}`
                },
                data: {
                    data: {
                        version: versions
                    }
                }
            })
            resolve()
        })
    }
    async getApplication(os) {
        return new Promise(async resolve => {
            await axios({
                method: "GET",
                url: `${Server.API_ENDPOINT}/application/${os}`,
                headers: {
                    'X-API-KEY': Server.API_DEV_KEY
                }
            }).then(snapshot => {
                return resolve(snapshot.data.data)
            }).catch(error => {
                console.log(error)
            })
        })
    }
}

export const ApplicationModel = new Application()
