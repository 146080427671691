import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@mui/material';

const FormDialog = ({ title, children, onSubmit, onClose, hasSubmitButton = true }) => {

    const handleClose = () => {
        onClose && onClose()
    };

    const handleSubmit = () => {
        if (onSubmit) {
            return onSubmit(handleClose)
        }
        handleClose();
    };


    return (
        <div>
            <Dialog open={true} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {children}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    {
                        hasSubmitButton &&
                        <Button onClick={handleSubmit} color="primary">
                            Submit
                        </Button>
                    }

                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FormDialog;
