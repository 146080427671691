import axios from "axios"
import React, { useEffect, useState } from "react"
import { Server } from '../../values/Server'
import { useNavigate } from "react-router-dom"
import { Buttons } from "../../components/Buttons"
import { Inputs } from "../../components/Inputs"
import { BILIS_headers } from "../../values/String"

export function RevokeBarangay() {
    const [psgc, setPSGC] = useState({
        region: null,
        province: null,
        municipal: null,
        barangay: null
    })

    const [selectedRegion, setSelectedRegion] = useState(null)
    const [selectedProvince, setSelectedProvince] = useState(null)
    const [selectedMunicipal, setSelectedMunicipal] = useState(null)
    const [selectedBarangay, setSelectedBarangay] = useState(null)
    const [chosenBarangayCode, setChosenBarangayCode] = useState(null)

    const navigate = useNavigate()


    function onRevokeBarangay() {
        const accessToken = localStorage.getItem('accessToken')

        axios({
            method: 'DELETE',
            url: `${Server.API_ENDPOINT}/barangay/${chosenBarangayCode}`,
            headers: {
                ...BILIS_headers,
                'Authorization': `Bearer ${accessToken}`
            }
        }).then(snapshot => {
            const dataSnapshot = snapshot.data.data
            console.log(dataSnapshot)
        }).catch(error => {
            alert(error.response.data.error.message)
        })
    }
  
    async function onChangePSGC(context, evt) {
        const value = evt.target.value

        if (context === 'region') {
            setSelectedProvince(null)
            setSelectedMunicipal(null)
            setSelectedBarangay(null)
            setSelectedRegion(value);
        }
        if (context === 'province') {
            setSelectedMunicipal(null)
            setSelectedBarangay(null)
            setSelectedProvince(value)
        }
        if (context === 'municipal') {
            setSelectedBarangay(null)
            setSelectedMunicipal(value)
        }

        if (context === 'barangay') {
            // Check if barangay is already registered
            setChosenBarangayCode(value)
        }
    }
    /**
    * @description Check for current user available
    * @returns 
    */
    async function checkCurrentUser() {
        const authToken = localStorage.getItem('authToken')
        const accessToken = localStorage.getItem('accessToken')
        if (!authToken || !accessToken) {
            return navigate('/login')
        }
    }
    /**
     * Get PSGC for region
     */
    async function getPSGCRegions() {
        const accesToken = localStorage.getItem('accessToken')
        const region = await axios({
            method: 'GET',
            url: `${Server.API_ENDPOINT}/psgc/filter/regions`,
            headers: {
                ...BILIS_headers,
                'Authorization': `Bearer ${accesToken}`
            }
        })
        const snapshot = region.data.data
        
        setPSGC({
            region: snapshot
        })
    }
    /**
     * Get PSGC for Province
     */
    async function getPSGCProvince(region) {
        const accesToken = localStorage.getItem('accessToken')
        const province = await axios({
            method: 'GET',
            url: `${Server.API_ENDPOINT}/psgc/filter/region/${region}/provinces`,
            headers: {
                ...BILIS_headers,
                'Authorization': `Bearer ${accesToken}`
            }
        })
        const snapshot = province.data.data
        return snapshot
    }
     /**
     * Get PSGC for Municipals
     */
    async function getPSGCMunicipal(region, province) {
        const accesToken = localStorage.getItem('accessToken')
        const municipal = await axios({
            method: 'GET',
            url: `${Server.API_ENDPOINT}/psgc/filter/region/${region}/province/${province}/municipals`,
            headers: {
                ...BILIS_headers,
                'Authorization': `Bearer ${accesToken}`
            }
        })
        const snapshot = municipal.data.data
        return snapshot
    }
    /**
     * Get PSGC for Municipals
     */
    async function getPSGCBarangay(region, province, municipal) {
        const accesToken = localStorage.getItem('accessToken')
        const barangay = await axios({
            method: 'GET',
            url: `${Server.API_ENDPOINT}/psgc/filter/region/${region}/province/${province}/municipal/${municipal}/barangays`,
            headers: {
                ...BILIS_headers,
                'Authorization': `Bearer ${accesToken}`
            }
        })
        const snapshot = barangay.data.data
        return snapshot
    }
    useEffect(() => {
        checkCurrentUser()
        getPSGCRegions()
    }, [])

    useEffect(()=>{
        async function fetchProvince(region){
            const province = await getPSGCProvince(region)
            setPSGC({
                ...psgc,
                province: province
            })
        }
        async function fetchMunicipal(region, province){
            const municipal = await getPSGCMunicipal(region, province)
            setPSGC({
                ...psgc,
                municipal: municipal
            })
        }
        async function fetchBarangay(region, province, municipal){
            const barangay = await getPSGCBarangay(region, province, municipal)
            setPSGC({
                ...psgc,
                barangay: barangay
            })
        }
        if (selectedRegion){
            fetchProvince(selectedRegion);
        }
        if (selectedProvince) {
            fetchMunicipal(selectedRegion, selectedProvince)
        }
        if (selectedMunicipal) {
            fetchBarangay(selectedRegion, selectedProvince, selectedMunicipal)
        }

    }, [selectedRegion, selectedProvince, selectedMunicipal, selectedBarangay])



    return (
        <div>
            <div style={styles.containerFlex}>
                <div style={styles.containerContent}>
                    <h2>Superadmin</h2>
                    <div style={styles.contentFlex}>
                        <p>Revoke a Barangay</p>
                        <select style={Inputs.dropdown} onChange={(evt) => onChangePSGC('region', evt)}>
                            <option key="0">Choose</option>
                            {
                               psgc && psgc?.region?.map(values => {
                                    return (
                                        <>
                                            <option key={values._id} value={values.Name}>{values.Name}</option>
                                        </>
                                    )
                                })
                            }
                        </select>
                        {
                            selectedRegion &&
                            <select style={Inputs.dropdown} onChange={(evt) => onChangePSGC('province', evt)}>
                                <option key="0">Choose</option>
                                {
                                    psgc?.province?.map(values => {
                                        return <option key={values._id} value={values.Name}>{values.Name}</option>
                                    })
                                }
                            </select>
                        }
                        {   
                            selectedProvince &&
                            <select style={Inputs.dropdown} onChange={(evt) => onChangePSGC('municipal', evt)}>
                                <option key="0">Choose</option>
                                {
                                    psgc?.municipal?.map(values => {
                                        return <option key={values._id} value={values.Name}>{values.Name}</option>
                                    })
                                }
                            </select>
                        }
                        {   
                            selectedMunicipal &&
                            <select style={Inputs.dropdown} onChange={(evt) => onChangePSGC('barangay', evt)}>
                                <option key="0">Choose</option>
                                {
                                    psgc?.barangay?.map(values => {
                                        return <option key={values._id} value={values._code}>{values.Name}</option>
                                    })
                                }
                            </select>
                        }
                        <input value={chosenBarangayCode} onChange={(evt) => setChosenBarangayCode(evt.target.value)}/>
                    </div>
                </div>
                <input style={Buttons.buttonEnabled} type="submit" onClick={onRevokeBarangay}></input>
            </div>
        </div>
    )
}

const styles = {
    containerFlex: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        maxWidth: '400px',
        padding: '30px',
        gap: '10px'
    },
    contentFlex: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        gap: '10px'
    },
    containerContent: {

    },
    inputText: {
        padding: '10px',
        border: '1px solid gray',
        borderRadius: '5px',
        outline: 'none'
    },

}