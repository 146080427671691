import { Chip, makeStyles, TextField } from "@mui/material";
import { useState } from "react";
import { BILIS_colors } from "../values/String";

export const Inputs = {
    inputText: {
        padding: '10px',
        border: '1px solid gray',
        borderRadius: '5px',
        outline: 'none',
        backgroundColor: 'whitesmoke'
    },
    dropdown: {
        border: '1px solid gray',
        borderRadius: '5px',
        padding: '10px'
    },
    errorText: {
      
    },
    textPrimary: {
        color: BILIS_colors.primary
    },
    titleText: {
        padding: 0,
        margin: '1px'
    },
    subText: {
        color: 'gray',
        padding: 0,
        margin: '1px'
    },
    bannerPrimary: {
        backgroundColor: BILIS_colors.primary,
        color: 'white',
        borderRadius: '10px',
        padding: '10px'
    },
    boldText: {
        fontWeight: 'bold'
    }
}


export function InputPrimary({ style, type, placeholder, value, onChange }) {
    return (
        <input 
            type={type}
            style={{
                padding: '10px',
                border: '1px solid gray',
                borderRadius: '5px',
                outline: 'none',
                backgroundColor: 'whitesmoke',
                ...style
            }} 
            placeholder={placeholder} 
            defaultValue={value}
            onChange={onChange}>
        </input>
    )
}
/**
 * @description Create an input with a chip
 */