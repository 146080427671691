import ReactDatePicker from "react-datepicker";
import { FlexColumnContainer, FlexRowContainer } from "../../../components/Container";
import { TypographyHeader2, TypographyHeader3, TypographyGray } from "../../../components/Typography";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import { UserModel } from "../../../services/database/models/User.Model";
import moment from "moment";
import { Typography } from "@mui/material";
import { BadgeModel } from "../../../services/database/models/Badge.Model";
import { InputPrimary } from "../../../components/Inputs";
import { ButtonDefault, ButtonDisabled } from "../../../components/Buttons";
import { useDebounce } from "use-debounce";
import { BILIS_colors } from "../../../values/String";


export function GiveUsers() {
    const [startDate, setStartDate] = useState(new Date());
    const [filteredUsersCount, setFilteredUsersCount] = useState(0)
    const [badges, setBadges] = useState()
    const [selectedBadge, setSelectedBadge] = useState()
    const [debouncedBadgeCode, setDebouncedBadgeCode] = useDebounce("", 1000)

    async function pickDate(date) {
        setStartDate(date)
        const momentDate = moment(date).startOf().format('YYYY-MM-DD')
        const users = await UserModel.getAllUsers(1, 10, `&filter[createdAt][$gte]=${momentDate}`)
        setFilteredUsersCount(users?.meta?.pagination?.totalDocuments)
    }

    async function submit() {
        setSelectedBadge()
        const momentDate = moment(startDate).startOf().format('YYYY-MM-DD')

        await BadgeModel.bulkGiveBadgeToUser(selectedBadge.attributes.code, {
            createdAt: {
                $gte: momentDate
            }
        })

        alert('Applied')
    }

    useEffect(() => { }, [badges, filteredUsersCount])

    useEffect(() => {
        async function init() {
            // Get input user info
            if (debouncedBadgeCode) {
                const badgesSearched = await BadgeModel.getAllBadges(1, 10, `&filter[code][$regex]=${debouncedBadgeCode}`)
                setBadges(badgesSearched)
            }
        }
        init()
    }, [debouncedBadgeCode])

    return (
        <FlexColumnContainer style={{ width: "50%" }}>
            {
                !selectedBadge 
                ?
                <FlexColumnContainer>
                    <TypographyHeader2>Pick a badge</TypographyHeader2>
                    <TypographyGray>Must put the user's badge code to search the specified badge</TypographyGray>
                    <InputPrimary onChange={(evt) => {
                        setDebouncedBadgeCode(evt.target.value)
                        if (evt.target.value == "") {
                            return setBadges()
                        }
                    }}></InputPrimary>
    
                    {
                        badges && badges.map((item, index) => {
                            return (
                                <FlexColumnContainer style={{ padding: 20, backgroundColor: 'whitesmoke', borderRadius: 10, marginTop: 10, cursor: "pointer" }} onClick={() => {
                                    setBadges()
                                    setSelectedBadge(item)
                                }}>
                                    <Typography style={{ fontWeight: 'bold' }}>{item.attributes.name}</Typography>
                                    <TypographyGray>{item.attributes.code}</TypographyGray>
                                </FlexColumnContainer>
                            )
                        })
                    }
                </FlexColumnContainer>
                :
                <FlexColumnContainer>
                    <FlexColumnContainer>
                        <TypographyHeader2>
                            Selected Badge
                        </TypographyHeader2>
                        <FlexRowContainer style={{ padding: 20, backgroundColor: BILIS_colors.primary, borderRadius: 10, marginTop: 10}}>
                            <Typography style={{ fontWeight: 'bold', color: 'white', cursor: 'pointer'}} onClick={() => {
                                setSelectedBadge()
                            }}>X</Typography>
                            <FlexRowContainer style={{marginLeft: 10}}></FlexRowContainer>
                            <Typography style={{ fontWeight: 'bold', color: 'white'}}>{selectedBadge?.attributes?.name}</Typography>
                        </FlexRowContainer>
                    </FlexColumnContainer>
                    <TypographyHeader2>Apply Badges By Date</TypographyHeader2>
                    <TypographyGray>Apply this badge by all users registered in the specified date below</TypographyGray>

                    <ReactDatePicker selected={startDate} onChange={(date) => pickDate(date)}></ReactDatePicker>
                    <TypographyHeader3>Selected {filteredUsersCount} users</TypographyHeader3>

                    {
                        filteredUsersCount <= 0 
                        ? <ButtonDisabled value={"Submit"}></ButtonDisabled>
                        : <ButtonDefault value={"Submit"} onClick={() => submit()}></ButtonDefault>
                    }
                </FlexColumnContainer>
            }
        </FlexColumnContainer>
    )
}