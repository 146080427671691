import { Box, Modal } from "@mui/material";
import { TypographyDefault, TypographyHeader2 } from "./Typography";
import { FlexColumnContainer, FlexRowContainer } from "./Container";
import { useState } from "react";
import { ButtonDefault, ButtonWhite } from "./Buttons";

const ModalDefault = ({ isOpen, title, description, onCancel, onAgree,  }) => {

    if (!isOpen) return null;

    return (
        <Modal open={isOpen}>
            <Box sx={styles.modalOverlay}>
                <FlexColumnContainer style={{ gap: '10px', backgroundColor: "white", padding: '20px', borderRadius: '10px' }}>
                    <TypographyHeader2>
                        {title}
                    </TypographyHeader2>
                    <TypographyDefault>
                        {description}
                    </TypographyDefault>
                    <div style={{paddingBottom: '20px'}}></div>
                    <FlexRowContainer style={{ justifyContent: 'right', gap: '10px' }}>
                        <ButtonWhite value={"Cancel"} onClick={onCancel}></ButtonWhite>
                        <ButtonDefault value={"Agree"} onClick={onAgree}></ButtonDefault>
                    </FlexRowContainer>
                </FlexColumnContainer>
            </Box>
        </Modal>

    );
};

export default ModalDefault;

const styles = {
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }

}