import { ArrayField, PlaceholderField, VariableField } from "./FormComponent"


export const TextBoxSelectComponent = (variable, placeholder, onChange, onArrayChange, callback, initializedArray) => {
    return  <>
            {
                VariableField(variable, onChange)
            }
            {
                PlaceholderField(placeholder, onChange)
            }
            {
                ArrayField(onArrayChange, callback, initializedArray)
            }
    </>

}