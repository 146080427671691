import { Grid, Pagination, Typography } from "@mui/material";
import { FlexColumnContainer } from "../../components/Container";
import { TypographyHeader2, TypographyError } from "../../components/Typography";
import { InputPrimary } from "../../components/Inputs";
import { IconImage, LoadingComponent } from "../../components/Miscs";
import { IconAsset } from "../../assets/IconAsset";
import IconMenu from "../../components/Menu";
import { useEffect, useState } from "react";
import { UserModel } from "../../services/database/models/User.Model";
import moment from "moment";
import { CapitalizeFirstLetter } from "../../utils/StringHelper";
import { useDebounce } from "use-debounce";
import FormDialog from "../../components/FormDialog";

export function Authentication() {
    const [users, setUsers] = useState()
    const [meta, setMeta] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [debouncedEmail, setDebouncedEmail] = useDebounce("", 1000)
    const [email, setEmail] = useState("")
    const [isSearching, setSearching] = useState(false)
    const [searchError, setSearchError] = useState()
    const [isLoading, setLoading] = useState(true)
    const [showDisablingDialog, setShowDisablingDialog] = useState(false)
    const [showDisablingReasonDialog, setShowDisablingReasonDialog] = useState(false)
    const [selectedUser, setSelectedUser] = useState()
    const [dialogError, setDialogError] = useState()
    const [dialogReason, setDialogReason] = useState()

    async function getUsers(page) {
        const users = await UserModel.getAllUsers(page || currentPage, 10)
        setUsers(users.data)
        setMeta(users.meta)
        setLoading(false)
    }

    useEffect(() => {
        getUsers()
    }, [currentPage])

    useEffect(() => {

    }, [email, showDisablingDialog, dialogError])
    useEffect(() => {
        async function init() {
            // Get input user info
            if (debouncedEmail) {
                try {
                    const userByEmail = await UserModel.getUserByEmail(debouncedEmail)
                    const userFullData = await UserModel.getUser(userByEmail.data.id)
                    setUsers([userFullData.data])
                } catch (error) {
                    setSearchError("No user found with this email")
                }
                setSearching(false)
            }
        }
        init()
    }, [debouncedEmail])

    const UserComponent = (index, userId, email, providers, createdAt, accountStatus = "active", accountDisablingReason, roles) => {
        const dialogOptions = [
            {
                name: accountStatus === "active" ? "Disable user" : "Enable user",
                onClick: async () => {
                    setDialogError("")
                    setSelectedUser({
                        id: userId,
                        accountStatus: accountStatus
                    })
                    if (accountStatus === "active") {
                        setShowDisablingDialog(true)
                    }
                    if (accountStatus === "disabled") {
                        await UserModel.updateUser(userId, {
                            accountStatus: "active",
                            accountDisablingReason: ""
                        })
                    }

                    if (users.length > 1 && email) {
                        return getUsers()
                    }

                    const collectedData = []
                    users.forEach(data => {
                        if (data.id === userId) {
                            collectedData.push({
                                ...data,
                                attributes: {
                                    ...data.attributes,
                                    accountStatus: selectedUser.accountStatus === "active" ? "disabled" : "active",
                                    accountDisablingReason: dialogReason
                                }
                            })
                        } else {
                            collectedData.push(data)
                        }
                    })
                    setUsers(collectedData)

                }
            },
            {
                name: roles.includes('tester') ? "Remove user as tester" : "Add user as tester",
                onClick: async () => {
                    const collectedData = []
                    let rolesMemo = roles
                    
                    if  (roles.includes('tester')) {
                        rolesMemo = roles.splice(0, 1, 'tester')
                        
                        await UserModel.updateUser(userId,  {
                            roles: ['tester']
                        }, {
                            array: {
                                operation: 'remove'
                            }
                        })
                    }
                    if  (!roles.includes('tester')) {
                        rolesMemo.push('tester')
                            
                        await UserModel.updateUser(userId,  {
                            roles: ['tester']
                        }, {
                            array: {
                                operation: 'add'
                            }
                        })
                    }



                    users.forEach(data => {
                        if (data.id === userId) {
                            collectedData.push({
                                ...data,
                                attributes: {
                                    ...data.attributes,
                                    roles: rolesMemo 
                                }
                            })
                        } else {
                            collectedData.push(data)
                        }
                    })
                    setUsers(collectedData)
                }
            },
        ]

        if (accountStatus === "disabled") {
            dialogOptions.push({
                name: "See disabling reason",
                onClick: () => {
                    setSelectedUser({
                        id: userId,
                        accountStatus: accountStatus,
                        accountDisablingReason: accountDisablingReason
                    })
                    setShowDisablingReasonDialog(true)
                }
            })
        }
        return (
            <FlexColumnContainer key={index} style={{
                backgroundColor: "whitesmoke", padding: 10, borderRadius: 10, marginBottom: 10
            }}>
                <Grid container direction={"row"} style={{ alignItems: "center" }}>
                    <Grid item xs={3.2}>
                        <Typography fontStyle={{ color: accountStatus === "active" ? "black" : "gray" }}>{email}</Typography>
                    </Grid>
                    <Grid item xs={1.3}>
                        {
                            providers.includes("google") && <IconImage src={IconAsset.Google}></IconImage>
                        }
                        {
                            providers.includes("email/password") && <IconImage src={IconAsset.Mail}></IconImage>
                        }
                    </Grid>
                    <Grid item xs={2.5}>
                        <Typography fontStyle={{ color: accountStatus === "active" ? "black" : "gray" }}>{createdAt && moment(createdAt).format("MMMM DD, YYYY hh:mm A")}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography fontStyle={{ color: accountStatus === "active" ? "black" : "gray" }}>{userId}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <FlexColumnContainer style={{ border: accountStatus === "active" ? "0.5px solid black" : "0.5px solid gray", textAlign: "center", borderRadius: 10 }}>
                            <Typography fontStyle={{ color: accountStatus === "active" ? "black" : "gray" }}>{roles.includes("developer") ? "Developer" : CapitalizeFirstLetter(accountStatus)}</Typography>
                        </FlexColumnContainer>
                    </Grid>
                    <Grid item xs={1}>
                        {
                            !roles.includes("developer") &&
                            <IconMenu
                                icon={IconAsset.MenuVertical}
                                items={dialogOptions}>
                            </IconMenu>
                        }
                    </Grid>
                </Grid>

            </FlexColumnContainer>
        )
    }

    return (
        <FlexColumnContainer>
            <TypographyHeader2>
                Authentication
            </TypographyHeader2>
            {
                showDisablingDialog &&
                <FormDialog
                    title={"Disabling User"}
                    onClose={() => {
                        setShowDisablingDialog(false)
                    }}
                    onSubmit={async (willClose) => {
                        if (dialogReason == "") return setDialogError("Please provide a reason...")

                        setDialogReason("")
                        try {
                            await UserModel.updateUser(selectedUser.id, {
                                accountStatus: "disabled",
                                accountDisablingReason: dialogReason
                            })

                            if (users.length > 1 && email) {
                                willClose()
                                return getUsers()
                            }

                            const collectedData = []
                            users.forEach(data => {
                                if (data.id === selectedUser.id) {
                                    collectedData.push({
                                        ...data,
                                        attributes: {
                                            ...data.attributes,
                                            accountStatus: selectedUser.accountStatus === "active" ? "disabled" : "active",
                                            accountDisablingReason: dialogReason
                                        }
                                    })
                                } else {
                                    collectedData.push(data)
                                }
                            })

                            setUsers(collectedData)
                            willClose()
                        } catch (error) {
                            willClose()
                            if (error.response.data.error.name === "UNABLE_TO_DISABLE_DEVELOPER") {
                                return alert("Unable to disable a developer's account")
                            }
                        }
                    }}>
                    <FlexColumnContainer>
                        <Typography>Please provide the reason for disabling this user.</Typography>
                        <InputPrimary placeholder={"Type the reason..."} onChange={(event) => {
                            const text = event.target.value
                            setDialogError("")
                            setDialogReason(text)
                        }}></InputPrimary>
                        <FlexColumnContainer style={{ marginTop: 10 }}>
                            {
                                dialogError &&
                                <TypographyError>
                                    {dialogError}
                                </TypographyError>
                            }
                        </FlexColumnContainer>
                    </FlexColumnContainer>
                </FormDialog>
            }
            {
                showDisablingReasonDialog &&
                <FormDialog
                    title={"Account Disabling Reason"}
                    hasSubmitButton={false}
                    onClose={() => {
                        setShowDisablingReasonDialog(false)
                    }}
                    onSubmit={async (willClose) => {
                        willClose()
                    }}>
                    <FlexColumnContainer>
                        <Typography>The account is disabled due to the ff. reason</Typography>
                        <FlexColumnContainer style={{ backgroundColor: "whitesmoke", padding: 10, borderRadius: 10 }}>
                            <Typography>{selectedUser.accountDisablingReason}</Typography>
                        </FlexColumnContainer>
                    </FlexColumnContainer>
                </FormDialog>
            }
            {
                !isLoading
                    ?
                    <FlexColumnContainer>
                        <div style={{ flex: 1, margin: 10, alignItems: "center", alignItems: "center", justifyContent: "center" }}>

                            <FlexColumnContainer style={{ maxWidth: "90%", margin: "auto" }}>
                                <InputPrimary value={email} placeholder={"Search for Email (Must type exact email)"} onChange={(event) => {
                                    const text = event.target.value
                                    setSearching(true)
                                    setSearchError()
                                    setEmail(text)
                                    setDebouncedEmail(text)
                                    if (!text) {
                                        setSearching(false)
                                        return getUsers()
                                    }
                                }}></InputPrimary>
                                {
                                    isSearching &&
                                    <FlexColumnContainer style={{ marginTop: 10 }}>
                                        <LoadingComponent />
                                    </FlexColumnContainer>
                                }
                                {
                                    searchError &&
                                    <FlexColumnContainer style={{ marginTop: 10 }}>
                                        <TypographyError>{searchError}</TypographyError>
                                    </FlexColumnContainer>
                                }
                                <Grid container direction={"row"} style={{ padding: 10 }}>
                                    <Grid item xs={3.3}>
                                        <Typography>Email</Typography>
                                    </Grid>
                                    <Grid item xs={1.2}>
                                        <Typography>Providers</Typography>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Typography>Time Created</Typography>
                                    </Grid>
                                    <Grid item xs={2.9}>
                                        <Typography>User Id</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>Status</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                    </Grid>
                                </Grid>
                                <FlexColumnContainer style={{ overflowY: "scroll", maxHeight: "60vh", height: "60vh" }}>
                                    {
                                        users && users.map((data, index) => {
                                            return UserComponent(index, data.id, data.attributes.email, data.attributes.authProvider, data.attributes.createdAt, data.attributes.accountStatus, data.attributes.accountDisablingReason, data.attributes.roles)
                                        })
                                    }
                                </FlexColumnContainer>
                            </FlexColumnContainer>
                        </div>
                        <FlexColumnContainer style={{ alignItems: "center" }}>
                            {
                                !email &&
                                <Pagination
                                    count={meta.pagination.totalPages}
                                    hidePrevButton={false}
                                    onChange={async (data, index) => {
                                        setCurrentPage(index)
                                        getUsers(index)
                                    }}
                                >
                                </Pagination>
                            }
                        </FlexColumnContainer>
                    </FlexColumnContainer>
                    :
                    <LoadingComponent />
            }
        </FlexColumnContainer>
    )
}