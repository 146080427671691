import './App.css';
import { Dashboard } from './pages/Dashboard';
import './assets/fonts/Nunito/Nunito-VariableFont_wght.ttf'
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { Login } from './pages/Login';
import { Pin } from './pages/Pin';
import { AuthorizeEmail } from './pages/AuthorizeEmail';
import { RevokeBarangay } from './pages/dashboard-pages/RevokeBarangay';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/revokeBarangay" element={<RevokeBarangay />} />
      <Route path="/pin" element={<Pin />} />
      <Route path="/authorize-email" element={<AuthorizeEmail />} />
      <Route path="/dashboard/*" element={<Dashboard />}/>
    </>
  )
);





