import axios from "axios"
import { UserModel } from "./User.Model"
import { Server } from "../../../values/Server"
import { browserName, browserVersion } from "react-device-detect"
import { v4 as uuidv4 } from 'uuid';

class DeviceLog {

    async getCurrentUsersDeviceLogs(pagination) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/device-logs/me?pagination[page]=${pagination.page}&pagination[limit]=${pagination.limit}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }

    async createDeviceLog() {
        const authenticationToken = await UserModel.getAuthenticationToken()
        const uniqueId =  uuidv4()
        localStorage.setItem('sessionId', uniqueId)

        function detectOS() {
            const platform = navigator.platform;
            if (platform.indexOf('Win') !== -1) return 'Windows';
            if (platform.indexOf('Mac') !== -1) return 'Mac OS';
            if (platform.indexOf('Linux') !== -1) return 'Linux';
            if (platform.indexOf('iPhone') !== -1) return 'iOS';
            if (platform.indexOf('Android') !== -1) return 'Android';
            if (platform.indexOf('iPad') !== -1) return 'iPad';
            return 'Unknown';
        }

        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: `${Server.API_ENDPOINT}/device-log/login`,
                headers: {
                    'Authorization': `Bearer ${authenticationToken}`
                },
                data: {
                    data: {
                        deviceId: null,
                        uniqueId: uniqueId,
                        deviceName: null,
                        baseOS: detectOS(),
                        brand: null,
                        manufacturer: null,
                        browser: browserName,
                        systemVersion: browserVersion,
                        model: null,
                        authorizationType: 'email/password',
                        isEmulator: null,
                        city: null,
                        content: null,
                        country: null,
                        district: null,
                        zip: null,
                        timezone: null,
                        isp: null,
                        org: null,
                        continent: null,
                        regionName: null
                    }
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }

    isCurrentDevice(browser, uniqueId, systemVersion, authorizationType) {
        const sessionId = localStorage.getItem('sessionId')
        const currentDevice = {
            browser: browserName,
            uniqueId: sessionId,
            systemVersion: browserVersion.toString(),
            authorizationType: 'email/password',
        }
        const toMatch = {
            browser: browser,
            uniqueId: uniqueId,
            systemVersion: systemVersion?.toString(),
            authorizationType: authorizationType,
        }        
        return JSON.stringify(currentDevice) === JSON.stringify(toMatch)
    }


    async signOutDevice(deviceLogId) {
        const accessToken = await UserModel.getAccessToken()

        return new Promise((resolve, reject) => {
            axios({
                method: 'DELETE',
                url: `${Server.API_ENDPOINT}/device-log/${deviceLogId}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }

}

export const DeviceLogModel = new DeviceLog()