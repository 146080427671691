import { IconAsset } from "../../../../assets/IconAsset";
import {
  FlexColumnContainer,
  FlexRowContainer,
} from "../../../../components/Container";
import { InputPrimary } from "../../../../components/Inputs";
import { IconImage, ToggleSwitch } from "../../../../components/Miscs";
import { TypographyDefault } from "../../../../components/Typography";

export const ValueField = (value, onChange) => {
  return (
    <FlexColumnContainer>
      <TypographyDefault style={{ fontWeight: "bold" }}>
        Value:
      </TypographyDefault>
      <InputPrimary
        value={value}
        onChange={(evt) => {
          onChange("value", evt.target.value);
        }}
      ></InputPrimary>
    </FlexColumnContainer>
  );
};

export const PlaceholderField = (placeholder, onChange) => {
  return (
    <FlexColumnContainer>
      <TypographyDefault style={{ fontWeight: "bold" }}>
        Placeholder:
      </TypographyDefault>
      <InputPrimary
        value={placeholder}
        onChange={(evt) => {
          onChange("placeholder", evt.target.value);
        }}
      ></InputPrimary>
    </FlexColumnContainer>
  );
};

export const PlaceholderValueField = (placeholderValue, onChange) => {
  return (
    <FlexColumnContainer>
      <TypographyDefault style={{ fontWeight: "bold" }}>
        Placeholder Value:
      </TypographyDefault>
      <InputPrimary
        value={placeholderValue}
        onChange={(evt) => {
          onChange("placeholderValue", evt.target.value);
        }}
      ></InputPrimary>
    </FlexColumnContainer>
  );
};

export const VariableField = (variable, onChange) => {
  return (
    <FlexColumnContainer>
      <TypographyDefault style={{ fontWeight: "bold" }}>
        Variable:
      </TypographyDefault>
      <InputPrimary
        value={variable}
        onChange={(evt) => {
          onChange("variable", evt.target.value);
        }}
      ></InputPrimary>
    </FlexColumnContainer>
  );
};

export const TipMessageField = (tipMessage, onChange) => {
  return (
    <FlexColumnContainer>
      <TypographyDefault style={{ fontWeight: "bold" }}>
        Tip Message:
      </TypographyDefault>
      <InputPrimary
        value={tipMessage}
        onChange={(evt) => {
          onChange("tipMessage", evt.target.value);
        }}
      ></InputPrimary>
    </FlexColumnContainer>
  );
};


export const ArrayField = (onChange, onUpdate, arrayValues = []) => {
  return (
    <FlexColumnContainer>
      <TypographyDefault style={{ fontWeight: "bold" }}>
        Array:
      </TypographyDefault>
      <FlexColumnContainer>
        {arrayValues.map((value, index) => {
          return (
            <FlexRowContainer key={index} style={{ alignItems: "center" }}>
              <FlexColumnContainer>
                <TypographyDefault style={{ fontWeight: "bold" }}>
                  Label:
                </TypographyDefault>
                <InputPrimary
                  value={value.label}
                  onChange={(evt) => {
                    onChange(
                      "array",
                      {
                        ...arrayValues[index],
                        label: evt.target.value,
                      },
                      index
                    );
                  }}
                ></InputPrimary>
              </FlexColumnContainer>
              <div style={{ width: "10px" }}></div>
              <FlexColumnContainer>
                <TypographyDefault style={{ fontWeight: "bold" }}>
                  Value:
                </TypographyDefault>
                <InputPrimary
                  value={value.value}
                  onChange={(evt) => {
                    onChange(
                      "array",
                      {
                        ...arrayValues[index],
                        value: evt.target.value,
                      },
                      index
                    );
                  }}
                ></InputPrimary>
              </FlexColumnContainer>
              <IconImage
                src={IconAsset.Trashcan}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  arrayValues.splice(index, 1);
                  onUpdate();
                }}
              ></IconImage>
            </FlexRowContainer>
          );
        })}
      </FlexColumnContainer>

      <IconImage
        src={IconAsset.Plus}
        style={{ cursor: "pointer" }}
        onClick={() => {
          arrayValues.push("");
          onChange(
            "array",
            {
              label: "",
              value: "",
            },
            arrayValues.length - 1
          );
        }}
      ></IconImage>
    </FlexColumnContainer>
  );
};

export const isRequiredText = (defaultIsRequiredState, onToggle) => {
  return (
    <FlexColumnContainer>
      <TypographyDefault style={{ fontWeight: "bold" }}>
        Is required:
      </TypographyDefault>
      <div style={{ width: "20px" }}></div>
      <ToggleSwitch
        defaultState={defaultIsRequiredState}
        onClick={onToggle}
      />
    </FlexColumnContainer>
  );
};
