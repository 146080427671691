import { BILIS_colors } from "../values/String"

export const Table = {
    default: {
        width: '100%',
    },
    identifier: {
        padding: '10px',
        backgroundColor: BILIS_colors.primary,
        color: 'white'
    },
    cells: {
        padding: 10,
        backgroundColor: 'whitesmoke'
    },
    td: {
        textAlign: 'center'
    }
}

