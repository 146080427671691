import axios from "axios"
import { UserModel } from "./User.Model"
import { Server } from "../../../values/Server"

class Badge {
    getAllBadges(page, limit, additionalQuery) {
        return new Promise(async resolve => {
            const currUser = await UserModel.getCurrentUser()
            await axios({
                method: "GET",
                url: `${Server.API_ENDPOINT}/badges?pagination[page]=${page}&pagination[limit]=${limit}${additionalQuery}`,
                headers: {
                    'Authorization': `Bearer ${currUser.accessToken}`
                }
            }).then(snapshot => {
                return resolve(snapshot.data.data)
            }).catch(error => {
                console.log(error)
            })
            resolve()
        })
    }
    bulkGiveBadgeToUser(badgeCode, filterQuery) {
        return new Promise(async resolve => {
            const currUser = await UserModel.getCurrentUser()
            await axios({
                method: "POST",
                url: `${Server.API_ENDPOINT}/give-badge/users`,
                headers: {
                    'Authorization': `Bearer ${currUser.accessToken}`
                },
                data: {
                    data: {
                        badgeCode: badgeCode,
                        filter: filterQuery
                    }
                }
            }).then(snapshot => {
                return resolve(snapshot.data.data)
            }).catch(error => {
                console.log(error)
            })
            resolve()
        })
    }
}

export const BadgeModel = new Badge()