import axios from "axios"
import { Server } from "../../../values/Server"
import { UserModel } from "../models/User.Model"
class PSGC {
    /**
     * @description This async function makes a GET request to retrieve a list of regions with an access token.
     * @returns 
     */
    async getRegions() {
        const accessToken = await UserModel.getAccessToken()
        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/psgc/regions`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
    /**
     * @description This code is an async function that makes a GET request with an access token to retrieve a list of provinces.
     * @param {*} regionId 
     * @returns 
     */
    async getProvinces(regionId) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/psgc/region/${regionId}/provinces`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
    /**
     * @description This code defines an asynchronous function that retrieves municipal data for a given province ID using
     * Axios to make a GET request, with an access token passed as a header.
     * The function returns a Promise that resolves with the response data
     * @param {*} provinceId 
     * @returns 
     */
    async getMunicipals(provinceId) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/psgc/province/${provinceId}/municipals`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
    /**
     * @description This code is an async function that gets the barangays 
     * (small administrative divisions) of a given municipal ID using an API endpoint.
     * It requires an access token and returns a promise that resolves to the retrieved data or rejects with an error.
     * @param {*} municipalId 
     * @returns 
     */
    async getBarangays(municipalId) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/psgc/municipal/${municipalId}/barangays`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }

    async getPSGCByCode(code) {
        const accessToken = await UserModel.getAccessToken()
        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/psgc/code/${code}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(error => {
                reject(error.response.data)
            })
        })
    }
}

export const PSGCModel = new PSGC()