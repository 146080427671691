import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import {
  FlexColumnContainer,
  FlexRowContainer,
} from "../../../components/Container";
import { InputPrimary } from "../../../components/Inputs";
import { TypographyDefault } from "../../../components/Typography";
import { IconImage, ToggleSwitch } from "../../../components/Miscs";
import { useEffect, useState } from "react";
import { IconAsset } from "../../../assets/IconAsset";
import { TitleComponent } from "./components/Title";
import { DividerComponent } from "./components/Divider";
import { TextBoxComponent } from "./components/TextBox";
import { FormValue } from "./components/FormValue";
import {
  TextBoxSelect,
  TextBoxSelectComponent,
} from "./components/TextBoxSelect";
import { isRequiredText } from "./components/FormComponent";
import { CalendarPicker } from "./components/CalendarPicker";
import { RadioGroupComponent } from "./components/RadioGroup";

export function FormComponent({
  index,
  value,
  placeholder,
  placeholderValue,
  variable,
  type,
  isRequired,
  array,
  componentOnChange,
  tipMessage,
}) {
  const [component, setComponent] = useState({
    type: type,
    value: value,
    placeholder: placeholder,
    placeholderValue: placeholderValue,
    variable: variable,
    array: array,
    isRequired: isRequired,
  });

  function onChange(key, value) {
    const data = {
      ...component,
      [key]: value,
    };

    setComponent(data);
    componentOnChange(index, data);
  }

  function onArrayChange(key, value, indexData) {
    let memo = component.array || [];
    memo[indexData] = value;
    const data = {
      ...component,
      [key]: memo,
    };
    setComponent(data);
    componentOnChange(index, data);
  }

  return (
    <FlexColumnContainer key={index}>
      <FlexColumnContainer>
        <FormControl>
          <InputLabel id="type-select-label">Type</InputLabel>
          <Select
            labelId="type-select-label"
            id="type-select-id"
            label="Type"
            placeholder="Type"
            defaultValue={"textbox"}
            value={component.type}
            onChange={(evt) => {
              const data = {
                //...component,
                type: evt.target.value,
              };
              setComponent(data);
              componentOnChange(index, data);
            }}
          >
            <MenuItem key={0} value={"textbox"}>
              {"Text Box"}
            </MenuItem>
            <MenuItem key={3} value={"calendarPicker"}>
              {"Calendar Picker"}
            </MenuItem>
            <MenuItem key={4} value={"title"}>
              {"Title"}
            </MenuItem>
            <MenuItem key={5} value={"formValue"}>
              {"Form Value"}
            </MenuItem>
            <MenuItem key={6} value={"divider"}>
              {"Divider"}
            </MenuItem>
            <MenuItem key={7} value={"nationality"}>
              {"Nationality"}
            </MenuItem>
            <MenuItem key={8} value={"textboxSelect"}>
              {"Textbox Select"}
            </MenuItem>
            <MenuItem key={9} value={"radioGroup"}>
              {"Radio Group"}
            </MenuItem>
          </Select>
        </FormControl>
      </FlexColumnContainer>
      {["textbox"].includes(component.type) &&
        TextBoxComponent(placeholder, placeholderValue, variable, onChange)}
      {["divider"].includes(component.type) &&
        DividerComponent(value, onChange)}
      {["title"].includes(component.type) && TitleComponent(value, onChange)}
      {["formValue"].includes(component.type) &&
        FormValue(placeholder, placeholderValue, variable, value, onChange)}
      {["textboxSelect"].includes(component.type) &&
        TextBoxSelectComponent(
          variable,
          placeholder,
          onChange,
          onArrayChange,
          () => {
            setComponent({
              ...component,
              array: component.array,
            });
          },
          component.array
        )}
      {["calendarPicker"].includes(component.type) &&
        CalendarPicker(
          placeholder,
          placeholderValue,
          variable,
          tipMessage,
          onChange
        )}
      {["radioGroup"].includes(component.type) &&
        RadioGroupComponent(
          variable,
          placeholder,
          tipMessage,
          onChange,
          onArrayChange,
          () => {
            setComponent({
              ...component,
              array: component.array,
            });
          },
          component.array
        )}
      <div style={{ height: "20px" }}></div>
      {!["title"].includes(component.type) &&
        isRequiredText(isRequired, () => {
          const data = {
            ...component,
            isRequired: !component.isRequired,
          };
          setComponent(data);
          componentOnChange(index, data);
        })}
    </FlexColumnContainer>
  );
}
