import { useEffect, useState } from "react"
import { FlexColumnContainer } from "../../../components/Container"
import DataGrid from "../../../components/DataGrid"
import { TypographyDefault, TypographyHeader2 } from "../../../components/Typography"
import { FormTemplateModel } from "../../../services/database/models/FormTemplate.Model"
import { useNavigate } from "react-router"
import ModalDefault from "../../../components/Modal"

export function FormTemplates() {
    const [addedQuery, setAddedQuery] = useState()
    const [formTemplates, setFormTemplates] = useState()
    const [formTemplatesMeta, setFormTemplatesMeta] = useState()
    const [modal, setModal] = useState({
        isOpen: false
    })
    const navigate = useNavigate()
    async function getFormTemplates() {
        const fetchFormTemplates = await FormTemplateModel.getFormTemplates({
            page: 1,
            limit: 10
        })
        let collectedData = []
        await fetchFormTemplates.data.reduce(async (promise, snapshot, index) => {
            await promise
            collectedData.push({
                id: snapshot.id, 
                ...snapshot.attributes,
                createdAt: new Date(snapshot.attributes.createdAt).toLocaleString(),
                updatedAt: new Date(snapshot.attributes.updatedAt).toLocaleString()
            })
        }, Promise.resolve())

        setFormTemplates(collectedData)
        setFormTemplatesMeta(fetchFormTemplates.meta)
    }

    useEffect(() => {
        getFormTemplates()
    }, [])


    return (
        <div>
            {
                modal.isOpen &&
                    <ModalDefault isOpen={modal.isOpen} title={modal.title} description={modal.description} onCancel={() => setModal({ isOpen: false})} onAgree={modal.onAgree}></ModalDefault>
            }
            <TypographyHeader2>
                Form Templates
            </TypographyHeader2>
            <TypographyDefault>
                A global settings for form templates.
            </TypographyDefault>
            <FlexColumnContainer>
                <DataGrid
                    headers={[
                        'name',
                        'variable',
                        'createdAt',
                        'updatedAt',
                    ]}
                    items={formTemplates}
                    filterOptions={{
                        attributes: [
                            'name',
                            'variable'
                        ]
                    }}
                    onFilterSubmit={(content) => {
                        setAddedQuery(`&filter[${content.attribute}]=${content.value}`)
                    }}
                    onAddSubmit={async ({ name, variable }) => {
                       await FormTemplateModel.createFormTemplate(name, variable)
                       getFormTemplates()
                    }}
                    onFilterClear={() => {
                        setAddedQuery()
                    }}
                    onItemClick={(item, index) => {
                        const chosenItem = formTemplates[index]
                        navigate(`/dashboard/form-template?id=${chosenItem.id}`)
                    }}
                    onItemRemoved={async (id, index, content) => {
                        setModal({
                            isOpen: true,
                            title: "Remove Form Template",
                            description: `Are you sure you want to remove this form template named '${content.name}'?`,
                            onAgree: async () => {
                                await FormTemplateModel.deleteFormTemplateById(id)
                                setModal({
                                    isOpen: false
                                })
                                getFormTemplates()
                            }
                        })
                    }}
                    onClickRefresh={getFormTemplates}
                />
            </FlexColumnContainer>
        </div>
    )
}