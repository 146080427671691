import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { Routes, Route, useLocation, useNavigate } from "react-router"
import { FlexColumnContainer, FlexRowContainer } from "../../components/Container"
import { NavBar } from "../../components/NavigationBar"
import { UserDashboardContent } from "./dashboard-content-pages/User.Dashboard.Content"
import { BarangayRegisteredDashboardContent } from "./dashboard-content-pages/BarangayRegistered.Dashboard.Content"
import { TypographyHeader2 } from "../../components/Typography"

export function DashboardContent() {
    const location = useLocation()
    const navigate = useNavigate()
    const [ isLoadng, setLoading ] = useState(true)


    const [navBarItem, setNavBarItem] = useState([
        {
            name: 'Users',
            urlParams: '/dashboard/content/users',
        },
        {
            name: 'Barangay Registered',
            urlParams: '/dashboard/content/barangay-registered',
        }
    ])

    // Initialize
    async function Init() {
        navigate('/dashboard/content/users')
        setLoading(false)
    }

    // Use Effect
    useEffect(() => {
        Init()
    }, [])
    //#endregion
    return (
        <div>
            <TypographyHeader2>
                Dashboard
            </TypographyHeader2>
            <FlexRowContainer>
                <NavBar navigate={navigate} location={location} navigationItems={navBarItem} />
            </FlexRowContainer>
            <FlexColumnContainer>
                <Routes>
                    <Route path="/users" element={<UserDashboardContent />} />
                    <Route path="/barangay-registered" element={<BarangayRegisteredDashboardContent />} />
                </Routes>
            </FlexColumnContainer>
        </div>
    )
}