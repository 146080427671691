import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { FlexColumnContainer } from "../../components/Container";
import { TypographyHeader2, TypographyHeader3, TypographyGray } from "../../components/Typography";
import { useEffect, useState } from "react";
import { GenesisAdminModel } from "../../services/database/models/GenesisAdmin.Model";
import { LoadingComponent } from "../../components/Miscs";
import { InputPrimary } from "../../components/Inputs";
import { useDebounce } from "use-debounce";
import { BarangayModel } from "../../services/database/models/Barangay.Model";
import { BILIS_colors } from "../../values/String";
import { UserModel } from "../../services/database/models/User.Model";

export function GenesisAdmin() {
    const [genesisAdmins, setGenesisAdmins] = useState(null)
    const [selectedGenesisAdmin, setSelectedGenesisAdmin] = useState()
    const [isLoading, setLoading] = useState(true)
    const [debouncedBarangayName, setDebouncedBarangayName] = useDebounce("", 500)
    const [searchText, setSearchText] = useState()
    const [searchedBarangay, setSearchedBarangay] = useState()
    const [registerToBarangayName, setRegisterToBarangayName] = useState()

    async function getGenesisAdmins() {
        const _genesisAdmins = await GenesisAdminModel.getGenesisAdmins()
        const _genesisAdminsData = _genesisAdmins.data

        // Get Selected Admin Barangay Registered
        setSelectedGenesisAdmin(_genesisAdminsData[0])
        setGenesisAdmins(_genesisAdminsData)
        setLoading(false)
    }

    async function searchBarangayRegistered(value) {
        setDebouncedBarangayName(value)
    }

    async function registerToBarangay(barangayClientId, barangayName) {
        setLoading(true)
        setRegisterToBarangayName(barangayName)
        setDebouncedBarangayName('')
        setSearchText('')
        setSearchedBarangay()
        const selectedGenesisAdminId = selectedGenesisAdmin.id
        await GenesisAdminModel.registerToBarangay(selectedGenesisAdminId, barangayClientId)
        setLoading(false)
    }

    useEffect(() => {
        async function init() {
            // Get input user info
            if (debouncedBarangayName) {
                const _barangayRegistereds = await BarangayModel.getBarangayByName(debouncedBarangayName)
                setSearchedBarangay(_barangayRegistereds)
            }
        }
        init()
    }, [debouncedBarangayName])

    useEffect(() => {
        getGenesisAdmins()
    }, [])

    useEffect(() => { }, [searchText])
    return (
        <FlexColumnContainer>
            {
                !isLoading
                    ?
                    <>
                        <TypographyHeader2>
                            Genesis Admin
                        </TypographyHeader2>
                        <FlexColumnContainer style={{marginBottom: 20}}>
                            <TypographyGray>
                                Genesis admins are pivotal in facilitating the establishment and organization of a barangay, playing a vital role in its initial setup and efficient functioning.
                            </TypographyGray>
                        </FlexColumnContainer>
                        {
                            registerToBarangayName &&
                            <FlexColumnContainer style={{ backgroundColor: BILIS_colors.primary, padding: 20, marginBottom: 20, borderRadius: 10 }}>
                                <Typography style={{ color: "white", fontWeight: "bold" }}>Successfully registered '{selectedGenesisAdmin.attributes.email}' to Barangay {registerToBarangayName}!</Typography>
                            </FlexColumnContainer>
                        }
                        <FormControl>
                            <InputLabel id="email-select-label">Genesis Admins</InputLabel>
                            <Select
                                labelId="email-select-label"
                                id="email-select-id"
                                label="Genesis Admins"
                                placeholder="Genesis Admins"
                                value={selectedGenesisAdmin?.attributes.email}
                                onChange={(evt) => {
                                    setSelectedGenesisAdmin(evt.target.value)
                                }}
                            >
                                {
                                    genesisAdmins.map((data, index) => {
                                        return (
                                            <MenuItem key={index} value={data.attributes.email}>{data.attributes.email}</MenuItem>
                                        )
                                    })
                                }

                            </Select>
                        </FormControl>
                        <FlexColumnContainer style={{ height: 20 }}></FlexColumnContainer>
                        <InputPrimary
                            value={searchText || ""}
                            placeholder={'Assign At'}
                            onChange={(evt) => {
                                setSearchText(evt.target.value)
                                searchBarangayRegistered(evt.target.value)
                            }}
                        >
                        </InputPrimary>

                        <FlexColumnContainer style={{ marginTop: 10 }}>

                            {
                                searchedBarangay &&
                                searchedBarangay.map((data, index) => {
                                    return (
                                        <FlexColumnContainer key={index} style={{ backgroundColor: 'whitesmoke', borderRadius: 10, padding: 10, cursor: "pointer" }} onClick={() => {
                                            registerToBarangay(data.id, data.attributes.name)
                                        }}>
                                            <TypographyHeader3 style={{ padding: 0 }}>{data.attributes.name}</TypographyHeader3>
                                            <TypographyGray>{data.include[0].attributes.name}</TypographyGray>
                                            <TypographyGray>{data.include[1].attributes.name}</TypographyGray>
                                        </FlexColumnContainer>
                                    )
                                })
                            }
                        </FlexColumnContainer>
                    </>
                    : <LoadingComponent></LoadingComponent>
            }
        </FlexColumnContainer>
    )
}