import React, { useEffect, useState } from "react"
import { IconAsset } from "../../../assets/IconAsset"
import { CardPrimary } from "../../../components/Card"
import { FlexColumnContainer, FlexRowContainer } from "../../../components/Container"
import { IconLargeImage, IconRoundLargeImage, LoadingComponent, } from "../../../components/Miscs"
import { Table } from "../../../components/Table"
import { BarangayModel } from "../../../services/database/models/Barangay.Model"

export function BarangayRegisteredDashboardContent() {
    const [barangayRegistereds, setBarangayRegistereds] = useState([])
    const [isLoading, setLoading] = useState(true)
    // Initialize
    async function Init() {
        await getRegisteredBarangays()
        setLoading(false)
    }
    // Fetch from API
    async function getRegisteredBarangays() {
        const getRegisteredBarangays = await BarangayModel.getRegisteredBarangays({
            pagination: {
                page: 1,
                limit: 20
            }
        })
        setBarangayRegistereds(getRegisteredBarangays)
    }
    useEffect(() => {
        Init()
    }, [])
    return (
        <div>
            {
                isLoading
                    ? <LoadingComponent />
                    :
                    <>
                        <p>Here are the barangays that are currently registered with BILIS PH</p>
                        <FlexColumnContainer style={{ overflowY: 'scroll', height: '450px' }}>
                            <table style={Table.default}>
                                <thead>
                                    <tr>
                                        <th style={Table.identifier}>Logo</th>
                                        <th style={Table.identifier}>Id</th>
                                        <th style={Table.identifier}>Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        barangayRegistereds.data.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th style={Table.cells}>
                                                        <FlexColumnContainer style={{ alignItems: 'center' }}>
                                                            {
                                                                !item.attributes.logo
                                                                    ? <IconRoundLargeImage src={IconAsset.ImagePlaceholder} style={{ backgroundColor: 'gray' }} />
                                                                    : <IconRoundLargeImage src={item.attributes.logo} imageStyle={{ borderRadius: '100%', width: '80px' }} />

                                                            }
                                                        </FlexColumnContainer>
                                                    </th>
                                                    <th style={Table.cells}>{item.id}</th>
                                                    <th style={Table.cells}>{item.attributes.name}</th>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </FlexColumnContainer>
                    </>
            }

        </div>
    )
}

