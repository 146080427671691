import { Server } from "./Server"

export const BILIS_colors = {
    primary: '#C10215',
    accent: '#1400E0',
    secondary: '#E65C5C',
    secondary_light: "#B4E2FC",
    accent_light: '#15C102'
}

export const BILIS_headers = {
    'X-API-KEY': Server.API_DEV_KEY,
}