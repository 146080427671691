import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { BILIS_colors } from "../values/String"
import { Inputs } from "./Inputs"


export function NavBar({ navigationItems }) {
    const navigate = useNavigate()
    const location = useLocation()
    const [pathname, setPathname] = useState()
    /**
    * @description Navigation through Nav bar
    * @param {*} evt 
    */
    function navigateOptions(evt) {
        const text = evt.target.innerText
        navigationItems.forEach((item) => {
            if (item.name == text) {
                setPathname(item.urlParams)
                navigate(item.urlParams)
            }
        })
    }
    useEffect(() => {
        setPathname(location.pathname)
    }, [location.pathname])
    return navigationItems &&
        navigationItems.map((item, index) => {
            const isSelected =item.urlParams === pathname
            return (
                <motion.div
                    key={index}
                    animate={{
                        backgroundColor: isSelected ? BILIS_colors.primary : '#FFF',
                        color: isSelected ? '#FFF' : BILIS_colors.primary,
                    }}
                    transition={{ 
                        type: 'spring'
                    }}
                    whileHover={!isSelected ? {
                        backgroundColor: '#FFF',
                        color: BILIS_colors.primary,
                    }: {
                        backgroundColor: BILIS_colors.primary,
                        color: '#FFF',
                    }}
                    style={isSelected ? styles.navigationBarButtonSelected : styles.navigationBarButton}
                    onClick={(evt) => { !isSelected && navigateOptions(evt) }}>
                    <motion.p style={Inputs.boldText}>{item.name}</motion.p>
                </motion.div>
            )

        })
}

const styles = {
    navigationBarButtonSelected: {
        border: '1px solid lightgray',
        backgroundColor: BILIS_colors.primary,
        borderColor: BILIS_colors.primary,
        color: '#FFF',
        borderRadius: '20px',
        padding: '20px',
        margin: '10px',
        textAlign: 'center',
        userSelect: 'none'
    },
    navigationBarButton: {
        border: '1px solid lightgray',
        borderColor: BILIS_colors.primary,
        color: BILIS_colors.primary,
        borderRadius: '20px',
        padding: '20px',
        margin: '10px',
        textAlign: 'center',
        cursor: 'pointer',
        userSelect: 'none'
    }
}