import { Chip, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { FlexColumnContainer } from "../../components/Container";
import { IconImage, LoadingComponent } from "../../components/Miscs";
import { TypographyHeader2, TypographyHeader3, TypographyDefault, TypographyGray } from "../../components/Typography";
import { ServiceModel } from "../../services/database/models/Service.Model";
import { ServerBuildModel } from "../../services/database/Server.Build.Model";


export function ServerBuilds() {
    const [serverBuilds, setServerBuilds] = useState(null)
    const [serverVersion, setServerVersion] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [isRefreshing, setRefreshing] = useState(false)

    async function getServerBuilds() {
        const fetchServerBuilds = await ServerBuildModel.getAllServerBuilds({
            page: 1,
            limit: 10
        })
        setServerBuilds(fetchServerBuilds)
        getServerVersion()
    }
    async function getServerVersion() {
        const fetchServerVersion = await ServiceModel.getServerVersion()
        setServerVersion(fetchServerVersion)
        setLoading(false)
        setRefreshing(false)
    }
    async function onRefresh() {
        setRefreshing(true)
        getServerBuilds()
    }
    useEffect(() => {
        getServerBuilds()
    }, [])

    const StatusChip = (status) => {
        let color = "warning"
        let text = "Building"
        if (status === "success") {
            color = "success"
            text = "Success"
        } else if (status === "failed") {
            color = "error"
            text = "Failed"
        } else if (status === 'live') {
            color = "error"
            text = "Live"
        }
        return <Chip label={text} color={color} />
    }
    return (
        <FlexColumnContainer>
            <TypographyHeader2>
                Server Builds
            </TypographyHeader2>
            <TypographyGray>
                This tool is utilized to monitor the success of a server infrastructure build. Only the 10 latest builds are displayed
            </TypographyGray>
            <FlexColumnContainer>
                {
                    !isLoading &&
                    <>
                        <FlexColumnContainer style={{ padding: 10, marginTop: 20, borderRadius: 10, alignItems: 'center', flexDirection: 'row'}}>
                            <TypographyDefault style={{ fontWeight: 800, marginRight: 10 }}>Current Server Version: </TypographyDefault>
                            <TypographyGray>{serverVersion.version}</TypographyGray>
                            <IconImage src={require('../../assets/icons/refresh.png')} style={{width: '25px'}} onClick={onRefresh}></IconImage>
                        </FlexColumnContainer>
                    </>
                }
            </FlexColumnContainer>
            <FlexColumnContainer style={{ width: '100%', overflowY: 'scroll', overflowX: 'hidden', height: '500px'}}>
                {
                    !isLoading && !isRefreshing
                        ?
                        serverBuilds.map((data, index) => {

                            if (index === 0) {
                                return (
                                    <>
                                        <Grid container rowSpacing={1} style={{ backgroundColor: 'whitesmoke', padding: 20, marginBottom: 20, borderRadius: 20, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Grid item xs={2}>
                                                <TypographyDefault style={{ fontWeight: 800 }}>{data.attributes.ref}</TypographyDefault>
                                                <TypographyGray>{data.attributes.branch}</TypographyGray>
                                                <TypographyGray>{data.id}</TypographyGray>
                                            </Grid>
                                            <Grid item>
                                                <FlexColumnContainer>
                                                    {
                                                        StatusChip('live')
                                                    }
                                                </FlexColumnContainer>
                                            </Grid>
                                            <Grid item xs={1.3}>
                                                <TypographyGray>{moment(data.attributes.createdAt).fromNow()}</TypographyGray>
                                            </Grid>
                                            <Grid item xs={2.7}>
                                                <TypographyDefault style={{ fontWeight: 800 }}>Highlight Commit:</TypographyDefault>
                                                <TypographyDefault>{data?.attributes.commits[0].message}</TypographyDefault>
                                            </Grid>
                                        </Grid>
                                        
                                    </>
                                )
                            }
                            return (
                                <>
                                    <Grid container rowSpacing={1} style={{ backgroundColor: 'whitesmoke', padding: 20, marginBottom: 20, borderRadius: 20, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Grid item xs={2}>
                                            <TypographyDefault style={{ fontWeight: 800 }}>{data.attributes.ref}</TypographyDefault>
                                            <TypographyGray>{data.attributes.branch}</TypographyGray>
                                            <TypographyGray>{data.id}</TypographyGray>
                                        </Grid>
                                        <Grid item>
                                            <FlexColumnContainer>
                                                {
                                                    StatusChip(data.attributes.status)
                                                }
                                            </FlexColumnContainer>
                                        </Grid>
                                        <Grid item xs={1.3}>
                                            <TypographyGray>{moment(data.attributes.createdAt).fromNow()}</TypographyGray>
                                        </Grid>
                                        <Grid item xs={2.7}>
                                            <TypographyDefault style={{ fontWeight: 800 }}>Highlight Commit:</TypographyDefault>
                                            <TypographyDefault>{data?.attributes.commits[0].message}</TypographyDefault>
                                        </Grid>
                                    </Grid>
                                    
                                </>
                            )
                        })
                        : <LoadingComponent></LoadingComponent>
                }



            </FlexColumnContainer>


        </FlexColumnContainer>
    )
}
