import axios from "axios"
import React, { useEffect, useState } from "react"
import { Server } from '../../values/Server'
import { useNavigate } from "react-router-dom"
import { InputPrimary, Inputs, InputWithChip } from "../../components/Inputs"
import { BILIS_headers } from "../../values/String"
import { TypographyHeader2, TypographyDefault, TypographyError, TypographyGray } from "../../components/Typography"
import { FlexColumnContainer, FlexRowContainer } from "../../components/Container"
import { Chip, Container, Button, Select, MenuItem, InputLabel, FormControl } from "@mui/material"
import { Box } from "@mui/system"
import { HorizontalSpacing, IconRoundLargeImage, LoadingComponent, VerticalSpacing } from "../../components/Miscs"
import { ButtonDefault, ButtonDisabled } from "../../components/Buttons"
import { PSGCModel } from "../../services/database/models/PSGC.Model"
import { UserModel } from "../../services/database/models/User.Model"
import { useDebounce } from "use-debounce"
import { BarangayModel } from "../../services/database/models/Barangay.Model"

export function RegisterBarangay() {
    const [psgc, setPSGC] = useState({
        region: null,
        province: null,
        municipal: null,
        barangay: null
    })

    const [selectedRegion, setSelectedRegion] = useState(null)
    const [selectedProvince, setSelectedProvince] = useState(null)
    const [selectedMunicipal, setSelectedMunicipal] = useState(null)
    const [selectedBarangay, setSelectedBarangay] = useState(null)
    const [error, setError] = useState(null)
    const [debouncedEmail, setDebouncedEmail] = useDebounce("", 1000)
    const [email, setEmail] = useState("")
    const [emails, setEmails] = useState([])
    const [userShowcase, setUserShowcase] = useState(null)
    const [isSearching, setSearching] = useState(false)
    const [isSaving, setSaving] = useState(false)

    const navigate = useNavigate()

    // Registration of barangay
    async function onRegisterBarangay() {
        const filterBarangay = psgc.barangay.filter(data => data.id === selectedBarangay)
        const barangay = filterBarangay[0]
        const tenDigitPSGC = barangay.attributes.tenDigitPSGC

        setSaving(true)
        try {
            await BarangayModel.registerBarangay(tenDigitPSGC, emails)
            alert("Successfull registered")
        } catch (error) {
            alert(error.error.message)
        }
        setSelectedRegion(null)
        setSelectedProvince(null)
        setSelectedMunicipal(null)
        setSelectedBarangay(null)
        setEmails([])
        setEmail(null)
        setDebouncedEmail(null)
        setSaving(false)
    }

    async function onAddEmail() {
        const isUserExists = await UserModel.isUserExists(email)
        const result = isUserExists.exists
        // Error Handler
        if (!result) {
            return setError("User with that email does not exists")
        }
        if (emails.includes(email)) {
            return setError("Email already added")
        }
        setUserShowcase(null)
        setDebouncedEmail("")
        setEmail("")
        setEmails([
            ...emails,
            email
        ])
    }

    async function removeEmail(emailToRemove) {
        let filterEmail = emails.filter(data => data !== emailToRemove)

        setEmails(filterEmail)
    }

    async function onChangePSGC(context, evt) {
        const value = evt.target.value

        if (context === 'region') {
            setSelectedProvince(null)
            setSelectedMunicipal(null)
            setSelectedBarangay(null)
            setSelectedRegion(value);
        }
        if (context === 'province') {
            setSelectedMunicipal(null)
            setSelectedBarangay(null)
            setSelectedProvince(value)
        }
        if (context === 'municipal') {
            setSelectedBarangay(null)
            setSelectedMunicipal(value)
        }

        if (context === 'barangay') {
            // Check if barangay is already registered
            setSelectedBarangay(value)
        }
    }
    /**
    * @description Check for current user available
    * @returns 
    */
    async function checkCurrentUser() {
        const authToken = localStorage.getItem('authToken')
        const accessToken = localStorage.getItem('accessToken')
        if (!authToken || !accessToken) {
            return navigate('/login')
        }
    }
    /**
     * Get PSGC for region
     */
    async function getPSGCRegions() {
        const regions = await PSGCModel.getRegions()
        setPSGC({
            region: regions.data
        })
    }
    /**
     * Get PSGC for Province
     */
    async function getPSGCProvince(regionId) {
        const provinces = await PSGCModel.getProvinces(regionId)
        return provinces.data
    }
    /**
    * Get PSGC for Municipals
    */
    async function getPSGCMunicipal(provinceId) {
        const municipals = await PSGCModel.getMunicipals(provinceId)
        return municipals.data
    }
    /**
     * Get PSGC for Municipals
     */
    async function getPSGCBarangay(municipalId) {
        const barangays = await PSGCModel.getBarangays(municipalId)
        return barangays.data
    }
    useEffect(() => {
        checkCurrentUser()
        getPSGCRegions()
    }, [])

    useEffect(() => {

    }, [email])
    useEffect(() => {
        async function init() {
            // Get input user info
            if (debouncedEmail) {
                setSearching(true)
                setUserShowcase(null)
                setError(null)
                try {
                    const user = await UserModel.getUserByIdentifier(email)
                    let collectedData = user
                    if (user.attributes.registeredAt) {
                        try {
                            const registeredBarangay = await BarangayModel.getBarangay(user.attributes.registeredAt)
                            collectedData.attributes.registeredAtInfo = registeredBarangay
                        } catch (error) {
                            console.log(error)
                        }
                    }
                    if (user.attributes.adminAt) {
                        try {
                            const registeredBarangay = await BarangayModel.getBarangay(user.attributes.adminAt)
                            collectedData.attributes.adminAtInfo = registeredBarangay
                        } catch (error) {
                            console.log(error)
                        }
                    }
                    setUserShowcase(collectedData)
                    setSearching(false)
                } catch (error) {
                    setUserShowcase(null)
                    setError("No user exists with that email")
                    setSearching(false)
                }

            }
        }
        init()
    }, [debouncedEmail])

    useEffect(() => {
        async function fetchProvince(region) {
            const province = await getPSGCProvince(region)
            setPSGC({
                ...psgc,
                province: province
            })
        }
        async function fetchMunicipal(province) {
            const municipal = await getPSGCMunicipal(province)
            setPSGC({
                ...psgc,
                municipal: municipal
            })
        }
        async function fetchBarangay(municipal) {
            const barangay = await getPSGCBarangay(municipal)
            setPSGC({
                ...psgc,
                barangay: barangay
            })
        }
        if (selectedRegion) {
            fetchProvince(selectedRegion);
        }
        if (selectedProvince) {
            fetchMunicipal(selectedProvince)
        }
        if (selectedMunicipal) {
            fetchBarangay(selectedMunicipal)
        }

    }, [selectedRegion, selectedProvince, selectedMunicipal, selectedBarangay])

    // Components
    const PSGCDropdownFields = () => {
        return (
            <Box>
                <TypographyDefault>Register a barangay</TypographyDefault>
                <VerticalSpacing />
                <Box type="grid" display="grid" gridTemplateColumns="repeat(1, 1fr)" gap={1}>
                    {
                        /**
                         * Form Control for Region
                         */
                    }
                    <FormControl>
                        <InputLabel id="region-select-label">Region</InputLabel>
                        <Select
                            labelId="region-select-label"
                            id="region-select"
                            label="Region"
                            placeholder="Province"
                            value={selectedRegion ? selectedRegion : ''}
                            onChange={(evt) => onChangePSGC('region', evt)}
                        >
                            {
                                psgc && psgc?.region?.map((values, index) => {
                                    return (
                                        <MenuItem key={index} value={values.id}>{values.attributes.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    {
                        /**
                         * Form Control for Province
                         */
                    }
                    <FormControl>
                        <InputLabel id="province-select-label">Province</InputLabel>
                        <Select
                            labelId="province-select-label"
                            id="province-select"
                            label="Region"
                            placeholder="Province"
                            value={selectedProvince ? selectedProvince : ''}
                            disabled={psgc.province ? false : true}
                            onChange={(evt) => onChangePSGC('province', evt)}
                        >
                            {
                                psgc && psgc?.province?.map((values, index) => {
                                    return (
                                        <MenuItem key={index} value={values.id}>{values.attributes.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    {
                        /**
                         * Form Control for Municipal
                         */
                    }
                    <FormControl>
                        <InputLabel id="municipal-select-label">Municipal</InputLabel>
                        <Select
                            labelId="municipal-select-label"
                            id="municipal-select"
                            label="Municipal"
                            placeholder="Municipal"
                            value={selectedMunicipal ? selectedMunicipal : ''}
                            disabled={psgc.municipal ? false : true}
                            onChange={(evt) => onChangePSGC('municipal', evt)}
                        >
                            {
                                psgc && psgc?.municipal?.map((values, index) => {
                                    return (
                                        <MenuItem key={index} value={values.id}>{values.attributes.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    {
                        /**
                         * Form Control for Barangay
                         */
                    }
                    <FormControl>
                        <InputLabel id="barangay-select-label">Barangay</InputLabel>
                        <Select
                            labelId="barangay-select-label"
                            id="barangay-select"
                            label="Barangay"
                            placeholder="Barangay"
                            value={selectedBarangay ? selectedBarangay : ''}
                            disabled={psgc.barangay ? false : true}
                            onChange={(evt) => onChangePSGC('barangay', evt)}
                        >
                            {
                                psgc && psgc?.barangay?.map((values, index) => {
                                    return (
                                        <MenuItem key={index} value={values.id}>{values.attributes.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        )
    }

    return (
        <div>
            <FlexColumnContainer>
                <TypographyHeader2>
                    Barangay Registration
                </TypographyHeader2>
                {
                    !isSaving
                        ?
                        <>
                            <FlexColumnContainer style={{ maxWidth: "50vw" }}>
                                <FlexColumnContainer>
                                    <PSGCDropdownFields />
                                    <VerticalSpacing />
                                    {/* <TypographyDefault>
                                        Assign admins
                                    </TypographyDefault>
                                    <Box sx={{ padding: '20px' }}>
                                        <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={1}>
                                            {
                                                emails &&
                                                emails.map(data => {
                                                    return <Chip label={data} size="small" variant="outlined" onDelete={() => { removeEmail(data) }} ></Chip>
                                                })
                                            }
                                        </Box>
                                    </Box>
                                    <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={1}>
                                        <InputPrimary type="text" placeholder="Email" value={email} onChange={(evt) => {
                                            setError(null)
                                            setDebouncedEmail(evt.target.value)
                                            setEmail(evt.target.value)
                                        }}></InputPrimary>
                                        {
                                            email
                                                ? <ButtonDefault value={'Add Email'} onClick={onAddEmail}></ButtonDefault>
                                                : <ButtonDisabled value={'Add Email'}></ButtonDisabled>
                                        }
                                        {
                                            emails && emails.length > 0 && selectedRegion && selectedProvince && selectedMunicipal && selectedBarangay
                                                ? <ButtonDefault value={'Register Barangay'} onClick={onRegisterBarangay}></ButtonDefault>
                                                : <ButtonDisabled value={'Register Barangay'}></ButtonDisabled>
                                        }
                                    </Box> */}
                                </FlexColumnContainer>
                                <ButtonDefault value={'Register Barangay'} onClick={onRegisterBarangay}></ButtonDefault>


                                <FlexColumnContainer display="grid" style={{ marginTop: 10, maxWidth: "30vw" }} gridTemplateColumns="repeat(3, 1fr)" gap={1}>
                                    {
                                        error && <TypographyError>{error}</TypographyError>
                                    }
                                </FlexColumnContainer>
                                {/** Searching Animation */}
                                {
                                    isSearching &&
                                    <LoadingComponent></LoadingComponent>
                                }

                                {
                                    userShowcase &&
                                    <FlexRowContainer display="grid" style={{ marginTop: 10, backgroundColor: "whitesmoke", borderRadius: 10, padding: 10 }} gridTemplateColumns="repeat(3, 1fr)" gap={1}>
                                        <IconRoundLargeImage imageStyle={{ width: 90, borderRadius: 50 }} src={userShowcase?.attributes?.photo}></IconRoundLargeImage>
                                        <FlexColumnContainer style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 10 }}>
                                            <FlexColumnContainer>
                                                <TypographyDefault style={{ fontWeight: 600 }}>{`${userShowcase?.attributes?.firstName} ${userShowcase?.attributes?.lastName}`}</TypographyDefault>
                                                {
                                                    userShowcase?.attributes?.registeredAt &&
                                                    <TypographyGray>Registered at: {userShowcase?.attributes?.registeredAtInfo?.attributes?.name}</TypographyGray>
                                                }
                                                {
                                                    userShowcase?.attributes?.adminAt &&
                                                    <TypographyGray>Already admin at: {userShowcase?.attributes?.adminAtInfo?.attributes?.name}</TypographyGray>
                                                }
                                            </FlexColumnContainer>
                                        </FlexColumnContainer>
                                    </FlexRowContainer>
                                }

                            </FlexColumnContainer>
                        </>
                        : <LoadingComponent></LoadingComponent>
                }
            </FlexColumnContainer>
        </div>
    )
}

const styles = {
    containerFlex: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        maxWidth: '400px',
        gap: '10px'
    },
    contentFlex: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        gap: '10px'
    },
    containerContent: {

    },
    inputText: {
        padding: '10px',
        border: '1px solid gray',
        borderRadius: '5px',
        outline: 'none'
    },

}