import { PlaceholderField, TipMessageField, ValueField, VariableField } from "./FormComponent"

export const FormValue = (placeholder, placeholderValue, variable, value, onChange) => {
    return <>
        {
            PlaceholderField(placeholder, onChange)
        }
        {
            ValueField(placeholderValue, onChange)
        }
        {
            VariableField(variable, onChange)
        }
        {
            TipMessageField(value, onChange)
        }
    </>

}