import { BILIS_colors } from "../values/String"

export function TypographyError({ style, children}) {
    return (
        <p style={{
            color: '#FFF',
            backgroundColor: BILIS_colors.secondary,
            padding: '10px',
            borderRadius: '10px',
            ...style
        }}>
            {children}
        </p>
    )
}
export function TypographyDefault({ style, children }) {
    return (
        <p style={{
            margin: 0,
            padding: 0,
            ...style
        }}>
            {children}
        </p>)
}
export function TypographyGray({ children }) {
    return (
        <p style={{
            color: 'gray'
        }}>
            {children}
        </p>)
}

export function TypographyHeader2({ style, children}) {
    return (
        <h2
            style={{
                userSelect: 'none',
                padding: '20px 0px',
                ...style
            }}
        >
            {children}    
        </h2>
    )
}

export function TypographyHeader3({ style, children}) {
    return (
        <h3
            style={{
                userSelect: 'none',
                padding: '20px 0px',
                ...style
            }}
        >
            {children}    
        </h3>
    )
}