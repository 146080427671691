import axios from "axios"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { IconAsset } from "../assets/IconAsset"
import { ButtonDefault, ButtonDisabled, ButtonWhite } from "../components/Buttons"
import { FlexColumnContainer, FlexRowContainer } from "../components/Container"
import { Inputs } from "../components/Inputs"
import { LoadingComponent, Miscs, VerticalSpacing } from "../components/Miscs"
import { TypographyError } from "../components/Typography"
import { UserModel } from "../services/database/models/User.Model"
import { Server } from '../values/Server'

export function Pin() {
    const [ credentials, setCredentials ] = useState()
    const [ user, setUser ] = useState({})
    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState()
    const [ disableSubmit, setDisableSubmit ] = useState()
    const navigate = useNavigate()


    function onChange(context, evt) {
        const text = evt.target.value
        setDisableSubmit(false)
        setCredentials({
            ...credentials,
            [context]: text
        })
    }
    /**
    * @description Check for current user available
    * @returns 
    */
    async function checkCurrentUser() {
        const authToken = await UserModel.getAuthenticationToken()
        if (!authToken) return navigate('/login')
        localStorage.removeItem('accessToken')
        setLoading(false)
    }
    // Pin Confirmation
    async function confirmPin() {
        try {
            setLoading(true)
            const authenticationToken = localStorage.getItem('authToken')
            const pin = await axios({
                method: 'POST',
                url: `${Server.API_ENDPOINT}/user/me/pin/confirm`,
                data: {
                    data: {
                        currentPin: credentials.pin
                    }
                },
                headers: {
                    'Authorization': `Bearer ${authenticationToken}`
                }
            })
            const pinSnapshot = pin.data.data.attributes
            localStorage.setItem('accessToken', pinSnapshot.accessToken)
            navigate('/dashboard')
        } catch(error) {
            setDisableSubmit(true)
            setLoading(false)
            const err = error.response.data?.error
            console.log(err)
            if (err.name === 'INVALID_CREDENTIALS') {
                return setError('Invalid Pin')
            }
            if (err.name === 'NULL_POINTER_ERROR') {
                localStorage.removeItem('authToken')
                return navigate('/login')
            }
            if (err.name === 'LOCATION_NOT_AUTHORIZED') {
                return navigate('/authorize-email')
            }
            return setError('An error occured')
        }
    }
    // Logout
    async function onLogout() {
        await UserModel.logout()
        return navigate('/login')
    }

    useEffect(() => {
        checkCurrentUser()
    }, [])
    useEffect(() => {}, [loading, error])

    //#region Components
    const ButtonComponent = () => {
        return credentials && credentials.pin && !disableSubmit
                    ? <ButtonDefault value="Submit" onClick={confirmPin} />
                    : <ButtonDisabled value="Submit"/>
    }
    //#endregion
    return (
        <div>
            <FlexColumnContainer style={{
                    alignItems: 'center',       
                    padding: '30px',
                    marginTop: '100px'
                }}>
                <FlexColumnContainer style={{width: '400px'}}> 
                    <FlexColumnContainer style={{alignItems: 'center'}}>
                        <img src={IconAsset.BILISLogo} style={{width: '200px'}}/>
                    </FlexColumnContainer>
                    <h2>Confirm Pin</h2>
                    <VerticalSpacing />
                    <input maxLength="4" type="password" style={Inputs.inputText} placeholder="Enter Pin" onChange={(evt) => onChange('pin', evt)}/>
                    <VerticalSpacing />
                    {
                        loading 
                        ? 
                        <FlexColumnContainer style={{alignItems: 'center'}}>
                            <LoadingComponent />
                        </FlexColumnContainer>
                        : 
                        <FlexColumnContainer style={{alignContent: 'center'}}>
                            <ButtonComponent />
                            <VerticalSpacing />
                            <ButtonWhite value={'Logout'} onClick={onLogout}></ButtonWhite>
                        </FlexColumnContainer>
                    }
                    {
                        error && <TypographyError>{error}</TypographyError>
                    }
                </FlexColumnContainer>
            </FlexColumnContainer>
        </div>
    ) 
}
const styles = {
    containerContent: {
        display: 'flex',
        width: '400px',
        flexDirection: 'column',
        padding: '25px',
    },
    bannerContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loading: {
        width: '40px',
        height: '38px'
    },
    loadingWrapper: {
        display: 'flex',
        justifyContent: 'center'
    }
}