import React from 'react'
import Trashcan from '../assets/icons/trashcan.svg'
import CloudUpload from '../assets/icons/cloud-upload.svg'
import { IconImage } from '../components/Miscs'
import Bug from '../assets/icons/bug-solid.svg'
import Inbox from '../assets/icons/inbox-solid.svg'
export const IconAsset = {
    BILISLogo: require('../assets/icons/bilis_logo.png'),
    Loading: require('../assets/icons/loading.gif'),
    BackButton: require('../assets/icons/icons8-back-100.png'),
    ChecklistWhite: require('../assets/icons/icons8-checklist-80.png'),
    CheckmarkWhite: require('../assets/icons/icons8-check-mark-100.png'),
    CloudUpload: CloudUpload,
    Plus: require('../assets/icons/plus.png'),
    TrashCanBlue: require('../assets/icons/icons8-delete-100.png'),
    RefreshBlue: require('../assets/icons/refresh-blue.png'),
    Trashcan: Trashcan,
    ImagePlaceholder: require('../assets/icons/bilis_image_placeholder.png'),
    Google: require("../assets/icons/google.png"),
    Mail: require("../assets/icons/mail.png"),
    MenuVertical: require("../assets/icons/menu-vertical.png"),
    Filter: require("./icons/filter-icon.png"),
    PlusBlue: require("./icons/plus-blue-icon.png"),
    VersionPrimary: require("./icons/icons8-version-100.png"),
    BrowserPrimary: require("./icons/icons8-browser-100.png"),
    EmailPrimary: require("./icons/icons8-email-100.png"),
    ClockPrimary: require("./icons/icons8-clock-100.png"),
    SignoutPrimary: require("./icons/icons8-sign-out-100.png"),
    SignoutWhite: require("./icons/icons8-sign-out-white-100.png"),
    Bug: () => {
        return (
            <IconImage src={Bug} style={{filter: 'invert(11%) sepia(97%) saturate(5551%) hue-rotate(349deg) brightness(80%) contrast(104%)'}}></IconImage>
        )
    },
    Inbox: () => {
        return (
            <IconImage src={Inbox} style={{filter: 'invert(11%) sepia(97%) saturate(5551%) hue-rotate(349deg) brightness(80%) contrast(104%)'}}></IconImage>
        )
    }
}